.event {
  width: 260px;
  padding: 20px;
}
.event-status-container {
  display: flex;
  border-bottom: 1px solid #efeff0;
  padding-bottom: 17px;
  align-items: center;
}
.event-status {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 13px;
}
.date {
  padding-top: 10px;
  white-space: nowrap;
}
