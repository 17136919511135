.schedule-container {
  background: var(--light-gray);
  border-left: 3px solid var(--gray-low-risk);
}

.title {
  font-size: 13px;
}

.date {
  font-size: 11px;
  color: var(--gray-low-risk);
}

.message {
  font-size: 12px;
}
