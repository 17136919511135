.select-component {
  z-index: 2;
}

.register-patient-card {
  max-width: min(80vw, 380px);
}

.create-account-button {
  background-color: var(--blue-700);
  color: var(--white);
  border: none;
  z-index: 1;
}
.create-account-button:disabled {
  opacity: 0.5;
}

.app-download-container {
  background-color: var(--gray-250);
  width: 210px;
  height: 70px;
}
