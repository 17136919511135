.main {
  padding: 19px 19.5px;
  white-space: nowrap;
}

.title {
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
}

.text, .disabledText {
  font-size: 12px;
  display: flex;
  align-items: center;
  padding-left: 15px;
}

.disabledText {
  padding-top: 10px;
  color: #8faac3;
}

.bottom {
  padding-top: 15px;
  display: flex;
}
