.login-background {
  background-color: #e5e5e5;
  width: 100%;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.login-background-image {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -5;
}
.login-background-image-gradient {
  background-image: linear-gradient(to bottom right, #96d7cc, #5676a5);
  z-index: -6;
}
.logos-container {
  flex-direction: row !important;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 20px;
}
.login-logo-top {
  display: block;
  max-width: 56px;
  max-height: 13px;
  width: auto;
  height: auto;

  margin-bottom: 11px;
}
.login-logo-bottom {
  display: block;
  max-width: 55px;
  max-height: 24px;
  width: auto;
  height: auto;
}
.login-user-thumb {
  width: 225px;
  height: 225px;
  background-color: #ffffff;
  border-radius: 112px;

  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);

  z-index: -4;

  filter: drop-shadow(0px 5px 20px rgba(183, 197, 209, 0.6));
}
.login-container {
  height: 100vh;
  width: 100vw;
  background-color: #ffffff;
  filter: drop-shadow(0px 5px 20px rgba(183, 197, 209, 0.6));
  flex-direction: row;
  padding-left: 0px;
  padding-right: 0px;
}
.login-row {
  height: 100vh;
  width: 100vw;
  font-size: 2vw;
}
.line-container {
  height: 25%;
}
.green-line {
  height: 0.5vh;
  /* margin-left: 23px; */
  margin-right: 5px;

  background: var(--secundary_color);
  box-shadow: 0px 3px 20px #50f4cb;
  border-radius: 3px;
}
.grey-line {
  height: 0.5vh;

  background: #d4e7fa;
  border-radius: 3px;

  margin-right: 23px;
  margin-left: 5px;
}
.grey-line-inv {
  height: 0.5vh;
  margin-left: 23px;
  margin-right: 5px;
  border-radius: 3px;
  background: #d4e7fa;
}
.green-line-inv {
  height: 0.5vh;
  border-radius: 3px;

  margin-right: 23px;
  margin-left: 5px;

  background: var(--secundary_color);
  box-shadow: 0px 3px 20px #50f4cb;
  border-radius: 3px;
}
.vertical-container {
  margin-bottom: 40px;
}
.login-form {
  padding: 4%;
  height: 100%;
  width: 100%;
  background-color: white;
}

.username-label {
  margin-top: 20%;
  margin-bottom: 0px;
}
.password-label {
  margin-top: 30px;
  margin-bottom: 0px;
}
.text-field {
  border-width: 0px 0px 1px 0px;
  border-color: #d4e7fa;
  width: 100%;
  font-size: 11px;
  margin-top: 0px;
  padding: 5px;
}
.text-field:focus {
  outline: none !important;
  border-width: 0px 0px 1px 0px;
  border-color: var(--secundary_color);
}
.text-field-token {
  border-width: 0px 0px 1px 0px;
  border-color: #d4e7fa;
  width: 10%;
  font-size: 11px;
  margin-top: 10px;
  padding: 5px;
  margin: 5px;
  font-size: 30px;
  color: var(--gray-low-risk);
  font-weight: 200;
  text-align: center;
}
.text-field-token:focus {
  outline: none !important;
  border-width: 0px 0px 1px 0px;
  border-color: var(--secundary_color);
}
.text-field-noborder {
  border-width: 0px 0px 0px 0px;
  border-color: transparent;
  font-size: 14px;
  flex: 1;
}
.text-field-noborder:focus {
  border-width: 0px 0px 1px 0px;
  border-color: #d4e7fa;
}
.login-bottom-buttons-container {
  flex-direction: column;
  justify-content: space-between;
  margin-top: 35%;
  width: 100%;
}
.token-bottom-buttons-container {
  flex-direction: column;
  justify-content: space-between;
  margin-top: 45%;
  width: 100%;
}
.token-bottom-buttons-container-smaller {
  flex-direction: column;
  justify-content: space-between;
  margin-top: 32%;
  width: 100%;
}
.greycheck {
  border: 1px solid #8faac4;
  border-radius: 2px;
  margin-left: 15px;
}
.remember-me {
  padding-left: 5px;
}
.centered-logo {
  align-items: center;
  justify-content: center;
  align-self: center;
  flex: 1;
  text-align: center;
}
.centered {
  align-items: center;
  justify-content: center;
  align-self: center;
  flex: 1;
  text-align: center;
}
.shield-logo {
  height: 80%;
  width: 80%;
}
.logo-container {
  padding: 5%;
  width: 25%;
  height: 25%;
  margin-top: 200px;
  border-radius: 50%;
  text-align: center;
  background-color: #f8f9fb;
  margin: 0 auto;
}
.top50 {
  margin-top: 50%;
}
.top25 {
  margin-top: 25%;
}
.top15 {
  margin-top: 15%;
}

.position-front {
  z-index: 5000;
}
.position-back {
  z-index: -5000;
}
.bottom-button-container {
  position: absolute;
  bottom: 0px;
  left: 0px;
  padding: 4%;
  width: 100%;
  background-color: white;
}
.vertically-centered-item {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}
.scroller-x {
  overflow-x: auto;
  overflow-y: hidden;
}
.scroller-y {
  overflow-x: scroll;
  width: 100%;
  height: 100%;
}
.textfield-button {
  position: absolute;
  max-width: 3%;
  max-height: 3%;
  width: auto;
  height: auto;
  cursor: pointer;
  margin-top: 3%;
  margin-left: -4%;
}
.dashboard-menu {
  min-height: 100vh;
  padding: 0px;
  width: 230px;
  align-items: flex-start;
  background-color: var(--primary_color);

  -webkit-transition: width 300ms ease-in-out, min-width 300ms ease-in-out,
    max-width 300ms ease-in-out;
  -moz-transition: width 300ms ease-in-out, min-width 300ms ease-in-out, max-width 300ms ease-in-out;
  -o-transition: width 300ms ease-in-out, min-width 300ms ease-in-out, max-width 300ms ease-in-out;
  transition: width 300ms ease-in-out, min-width 300ms ease-in-out, max-width 300ms ease-in-out;
}
.dashboard-menu.shrink {
  width: 75px;
  min-width: 75px;
  max-width: 75px;
  padding: 0px;

  -webkit-transition: width 300ms ease-in-out, min-width 300ms ease-in-out,
    max-width 300ms ease-in-out;
  -moz-transition: width 300ms ease-in-out, min-width 300ms ease-in-out, max-width 300ms ease-in-out;
  -o-transition: width 300ms ease-in-out, min-width 300ms ease-in-out, max-width 300ms ease-in-out;
  transition: width 300ms ease-in-out, min-width 300ms ease-in-ou, max-width 300ms ease-in-outt;
}
.dashboard-menu.shrink p {
  display: none;
}
.dashboard-header-container {
  background-image: linear-gradient(to bottom right, var(--primary_color), var(--secundary_color));
}
.dashboard-header-container.shrink {
  display: flex;
  justify-content: center;
  align-items: center;
}
.dashboard-user-thumb-container {
  z-index: -6;
  padding: 22%;
  height: 225px;
  border-bottom: 1px solid var(--primary_color);

  -webkit-transition: width 300ms ease-in-out, height 300ms ease-in-out;
  -moz-transition: width 300ms ease-in-out, height 300ms ease-in-out;
  -o-transition: width 300ms ease-in-out, height 300ms ease-in-out;
  transition: width 300ms ease-in-out, height 300ms ease-in-out;
}
.dashboard-user-thumb-container.shrink {
  width: 0px;
  height: 55px;
  padding: 0px;

  -webkit-transition: width 300ms ease-in-out, height 300ms ease-in-out;
  -moz-transition: width 300ms ease-in-out, height 300ms ease-in-out;
  -o-transition: width 300ms ease-in-out, height 300ms ease-in-out;
  transition: width 300ms ease-in-out, height 300ms ease-in-out;
}
.dashboard-burger.shrink {
  transform: rotate(-180deg);
  right: 30px;
  margin: 0;

  -webkit-transition: transform 300ms ease-in-out, left 300ms ease-in-out;
  -moz-transition: transform 300ms ease-in-out, left 300ms ease-in-out;
  -o-transition: transform 300ms ease-in-out, left 300ms ease-in-out;
  transition: transform 300ms ease-in-out, left 300ms ease-in-out;
}
.dashboard-menu.shrink .dashboard-button-text {
  width: 0px;

  -webkit-transition: width 300ms ease-in-out, height 300ms ease-in-out;
  -moz-transition: width 300ms ease-in-out, height 300ms ease-in-out;
  -o-transition: width 300ms ease-in-out, height 300ms ease-in-out;
  transition: width 300ms ease-in-out, height 300ms ease-in-out;
}
.dashboard-buttons {
  text-transform: uppercase;
}
.dashboard-buttons.shrink {
  text-transform: uppercase;
  border-top: 1px solid var(--hover_color);
}
.dashboard-buttons.shrink .dashboard-button-notification {
  font-size: 10px;
  padding-left: 11px;
}
.component-content {
  padding: 0px;
  overflow: auto;
  background-color: #f7f7f9;
  display: flex;
  flex-direction: column;
}
.dashboard-details {
  width: 100%;
  background-color: #f7f7f9;
  padding: 15px;
}
.dashboard-detail-container {
  background-color: transparent;
  padding: 10px !important;
  margin-bottom: 5px;
  min-width: 350px;
  max-width: 100% !important;
  flex-grow: 1 !important;
  width: 100%;
  max-height: 450px;
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.05));
}
.dashboard-detail-container.bigger {
  background-color: transparent;
  padding: 10px;
  height: 435px;
  margin-bottom: 15px;

  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.05));
}
.dashboard-detail-container.bigger .dashboard-detail {
  height: 435px;
}
.dashboard-detail {
  background-color: white;
  border-radius: 5px;
  height: 447px;
  overflow: hidden;
  height: 100%;
  padding-bottom: 20px;
}
.dashboard-detail-content {
  width: 100%;
  overflow: auto;
  padding: 17px;
  padding-bottom: 0;
  height: 88%;
}
.component-container {
  font-family: 'Barlow' !important;
}
.dashboard-user-thumb {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border-radius: 50%;

  padding: 20%;

  z-index: 1;

  filter: drop-shadow(0px 5px 20px rgba(183, 197, 209, 0.6));

  align-items: center;
  align-content: center;
  vertical-align: middle;
}
.dashboard-thumb {
  height: 100%;
  width: 100%;
}
.dashboard-burger {
  margin-top: 16px;
  margin-right: 19px;
  height: 14px;
}
.dashboard-burger:hover {
  cursor: pointer;

  -webkit-transition: transform 300ms ease-in-out;
  -moz-transition: transform 300ms ease-in-out;
  -o-transition: transform 300ms ease-in-out;
  transition: transform 300ms ease-in-out;
}
.dashboard-button {
  color: white;
  padding: 15px;
  border-bottom: 1px solid var(--hover_color);
  background-color: var(--primary_color);
  cursor: pointer;
  height: 50px;
}
.dashboard-button svg {
  fill: white;
  stroke: white;
}
.dashboard-button:hover svg {
  fill: var(--secundary_color);
  stroke: var(--secundary_color);
}
.dashboard-button:hover {
  color: var(--secundary_color);
  padding: 15px;
  border-bottom: 1px solid var(--primary_color);
  border-left: 5px solid var(--secundary_color);
  background-color: var(--hover_color);
  cursor: pointer;
  padding: 15px 15px 15px 10px;
}
.dashboard-button p {
  padding: 0px;
  margin-bottom: 0px;
}
.dashboard-button-text {
  padding-right: 0px;
  margin-bottom: 0px;
  -webkit-transition: width 300ms ease-in-out, height 300ms ease-in-out;
  -moz-transition: width 300ms ease-in-out, height 300ms ease-in-out;
  -o-transition: width 300ms ease-in-out, height 300ms ease-in-out;
  transition: width 300ms ease-in-out, height 300ms ease-in-out;
  font-size: 0.84rem;
  display: flex;
  align-items: center;
  height: 100%;
  line-height: 0.84rem;
  white-space: nowrap;
}
.dashboard-button-text p {
  overflow: hidden;
}
.dashboard-button-selected {
  color: var(--secundary_color);
  padding: 15px 15px 15px 10px;
  border-bottom: 1px solid var(--primary_color);
  border-left: 5px solid var(--secundary_color);
  background-color: var(--hover_color);
  cursor: pointer;
  height: 50px;
}
.dashboard-button-selected p {
  padding: 0px;
  margin-bottom: 0px;
}
.dashboard-menu-icon {
  height: 15px;
  width: 15px;
}
.dashboard-profile-icon {
  height: 15px;
  width: 15px;
  border-radius: 50%;
}
.patient-ring-profile {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  position: absolute;
}
.dashboard-button-notification {
  display: block;
}
.dashboard-notification-container {
  background-color: #373372;
  font-size: 12px;
  border-radius: 11px;
  width: 22px;
  height: 22px;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding-top: 1px;
}
.dashboard-header {
  border: 1px solid #efeff0;
  height: 50px;
  background-color: white;
  background-color: #f8f9fb;
}
.dashboard-todo {
  border: 1px solid #efeff0;
  height: 50px;
  background-color: #f8f9fb;
  padding: 25px;
}
.dashboard-patient-scroller {
  border: 1px solid #efeff0;
  height: 100px;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.dashboard-patient-scroller-band {
  height: 100px;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.dashboard-separator {
  border-top: 1px solid #efeff0;
  height: 8px;
  background-color: #f8f9fb;
  display: flex;
  align-items: center;
  text-align: center;
  cursor: grab;
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.05));
}
.dashboard-patient-lines {
  border: 1px solid #efeff0;
  height: 11px;
  padding-top: 2px;
  padding-bottom: 2px;
  display: flex;
  flex-direction: row;
  background-color: #f8f9fb;
}
.adherence-grid {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(6, auto);
  grid-template-rows: auto;
  grid-gap: 0px;
  align-items: center;
  justify-items: center;
}
.adherence-line-gray, .adherence-line-blue {
  height: 0px;
  border-top: 6px solid #e1e6f1;
  cursor: pointer;
  margin: 2px;
  max-width: 20px;
  width: 20px;
}
.adherence-line-gray:hover {
  border-top: 6px solid;
}
.adherence-line-blue {
  border-top: 6px solid #80cce4;
}
.adherence-line-blue:hover {
  border-top: 6px ridge var(--hover_color);
}


.patient-line-dashed {
  height: 5px;
  background: repeating-linear-gradient(-35deg,
    #F5F8F9,
    #F5F8F9 2px,
    #CFD6E2 2px,
    #CFD6E2 4px);
  cursor: pointer;
  margin-left: 4px;
  flex: 1;
}
.patient-line-green-faint {
  height: 0px;
  border-top: 5px solid #c4d8b5;
  cursor: pointer;
  margin-left: 4px;
  flex: 1;
}
.patient-line-green {
  height: 0px;
  border-top: 5px solid var(--secundary_color);
  cursor: pointer;
  margin-left: 4px;
  flex: 1;
}
.patient-line-gray {
  height: 0px;
  border-top: 5px solid #cfd6e2;
  cursor: pointer;
  margin-left: 4px;
  flex: 1;
}
.patient-line-gray:hover, 
.patient-line-dashed:hover, 
.patient-line-green-faint:hover, 
.patient-line-green:hover {
  border-top: 5px solid var(--hover_color);
}
.dashboard-patient-detail {
  border-right: 1px solid #efeff0;
  height: 100%;
  background-color: white;
  padding: 5px 25px 5px 25px;
}
.dashboard-patient-detail-nohover {
  border-right: 1px solid #efeff0;
  height: 100%;
  background-color: white;
  padding: 5px 25px 5px 25px;
}
.dashboard-patient-detail:hover {
  filter: drop-shadow(0px 5px 20px rgba(183, 197, 209, 0.6));
  cursor: pointer;
}
.dashboard-patient-event-detail {
  position: absolute;
  z-index: 1000;
  border-right: 1px solid #efeff0;
  height: 90px;
  max-height: 240px;
  background-color: #f8f9fb;
  padding: 10px;
  min-width: 400px;
  top: 213px;
  margin-left: -25px;

  filter: drop-shadow(0px 5px 20px rgba(173, 182, 189, 0.6));
  cursor: pointer;
}
.dashboard-patient-event-detail:hover {
  filter: drop-shadow(0px 5px 15px rgba(174, 182, 189, 0.6));
  cursor: pointer;
}
.dashboard-patient-event-detail.bigger-detail {
  position: absolute;
  z-index: 1000;
  border-right: 1px solid #efeff0;
  height: 240px;
  max-height: 240px;
  background-color: #f8f9fb;
  padding: 5px 25px 5px 25px;

  top: 213px;
  margin-left: -25px;

  filter: drop-shadow(0px 5px 20px rgba(202, 214, 224, 0.6));
  cursor: pointer;
}
.dashboard-patient-detail-profile {
  border-right: 1px solid #efeff0;
  height: 100%;
  background-color: white;
  padding: 5px 25px 5px 25px;
}
.dashboard-patient-detail-thumb {
  padding: 25px;
}
.dashboard-patient-detail-top {
  border-bottom: 1px solid #efeff0;
  background-color: white;
  padding: 10px;
  display: flex;
  flex-direction: row;
  display: flex;
  flex-wrap: nowrap;
  flex: 1;
}
.dashboard-patient-detail-bottom {
  background-color: white;
  padding: 10px 10px 0 10px;
  font-size: 12px;
  display: flex;
  flex-wrap: nowrap;
  flex: 1;
}
.one-lined {
  display: inline;
  white-space: nowrap;
}
.dashboard-patient-search {
  color: var(--gray-low-risk);
  display: flex;
  flex-wrap: nowrap;
  flex: 1;
}
input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}
.dashboard-notifications {
  color: #8faac4;
  display: flex;
  flex-wrap: nowrap;
  font-size: 14px;
}
.dashboard-profile {
  color: #8faac4;
  display: flex;
  flex-wrap: nowrap;
  font-size: 13px;
  border-left: 2px solid #EFEFF0;
  border-bottom: 2px solid #EFEFF0;
  padding: 0px 25px;
  cursor: pointer;
  align-items: center;
  width: 200;
}
.dashboard-patient {
  color: #8faac4;
  display: flex;
  flex-wrap: nowrap;
  font-size: 13px !important;
  width: 100%;
  color: #393e48;
}
.date-selector {
  color: #8faac4;
  font-size: 12px;
  display: flex;
  cursor: pointer;
  padding-top: 3px;
  display: flex;
  align-items: center;
}
.tag-container {
  background: #e7eaed;
  border: 1px solid #cfd6e2;
  box-sizing: border-box;
  border-radius: 80px;
  font-size: 11px;
  padding: 2px 15px 2px 15px;
  margin-right: 5px;
  overflow: hidden;
  white-space: nowrap;
}
.tag-container-round-margin {
  margin-top: -5px;
}

.tag-container-round,
.tag-container-round-removable,
.tag-container-round-addable {
  background: #e7eaed;
  border: 2px solid white;
  box-sizing: border-box;
  border-radius: 80px;
  font-size: 12px;
  padding: 5px 5px 5px 5px;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  align-items: center;
  /* height: 22px; */
}

.tag-container-round-removable::before {
  content: '';
  background-image: url('./assets/icons/remove_tag_icon.svg');
  position: relative;
  left: -13px;
  display: inline-block;
  width: 16px;
  height: 16px;
  -moz-border-radius: 7.5px;
  -webkit-border-radius: 7.5px;
  border-radius: 7.5px;
  background-color: #f8f9fb;
}

.tag-container-round-addable::before {
  content: '';
  background-image: url('./assets/icons/add_tag_icon.svg');
  position: relative;
  left: -13px;
  display: inline-block;
  width: 16px;
  height: 16px;
  -moz-border-radius: 7.5px;
  -webkit-border-radius: 7.5px;
  border-radius: 7.5px;
  background-color: #f8f9fb;
}

.alert-item-container {
  background: transparent;
  border-radius: 80px;
  font-size: 11px;
  padding: 3px 0px 3px 0px;
  margin-right: 5px;
  width: 25px;
  height: 25px;
  max-width: 25px;
  max-height: 25px;
  min-width: 25px;
  min-height: 25px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.alert-item-container-lg {
  background: transparent;
  border-radius: 50%;
  font-size: 11px;
  padding: 3px 0px 3px 0px;
  margin-right: 5px;
  width: 35px;
  height: 35px;
  max-width: 35px;
  max-height: 35px;
  min-width: 35px;
  min-height: 35px;
  text-align: center;
}

.alert-item-container-hexagon {
  background: transparent;
  font-size: 11px;
  padding: 4px 0px 3px 0px;
  margin-right: 5px;
  width: 27px;
  height: 27px;
  max-width: 27px;
  max-height: 27px;
  min-width: 27px;
  min-height: 27px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  background-image: url('./assets/icons/hexagon.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.alert-item-container-hexagon-lg {
  background: transparent;
  font-size: 11px;
  padding: 4px 0px 3px 0px;
  margin-right: 5px;
  width: 37px;
  height: 37px;
  max-width: 37px;
  max-height: 37px;
  min-width: 37px;
  min-height: 37px;
  text-align: center;

  background-image: url('./assets/icons/hexagon.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.dashboard-alert-container {
  background-color: #f8f9fb;
  display: flex;
}
.dashboard-item-header {
  display: flex;
  font-size: 13px;
  border-bottom: 1px solid #efeff0;
  padding: 15px;
  width: 100%;
}
.patient-ring-big {
  height: 72px;
  width: 72px;
}

.to-do-paginator {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex: 1;
  font-size: 12px;
  padding-top: 2px;
}
.todo-page-button {
  margin-right: 15px;
  margin-left: 15px;
  color: #1890ff;
  cursor: pointer;
  font-weight: 500;
}
.todo-page-button-disabled {
  margin-right: 15px;
  margin-left: 15px;
  color: #85c1fa;
  cursor: pointer;
}
.dashboard-profile-patient {
  min-height: 58px;
}
.dashboard-profile-patient:hover {
  filter: drop-shadow(0px 5px 20px rgba(183, 197, 209, 0.6));
}
.card-footer {
  height: 40px;
  background-color: white;
  border: 0px solid transparent;
  align-content: center;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.link {
  background: none;
  border: none;
  font-weight: 500;
  color: #1890ff;
  cursor: pointer;
  outline: none;
}

.link:hover {
  text-decoration: underline;
}

.link:disabled {
  text-decoration: none;
  cursor: auto;
  color: var(--black-text);
}

.small-link {
  font-size: 12px;
  color: #1890ff;
  cursor: pointer;
}
.gauge-ring-text {
  line-height: 10px;
  position: absolute;
  transform: scale(-1, 1);
}
.gauge-ring-container {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -5px;

  -moz-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1);
  transform: scale(-1, 1);
}
.gauge-ring-big {
  height: 30px;
  width: 30px;
}
.ghost-button.btn-primary {
  background-color: transparent;
  color: #1890ff;
  border-color: #1890ff;
}
.ghost-button.btn-success {
  background-color: transparent;
  color: #86b463;
  border-color: #86b463;
}
.ghost-button.btn-primary:hover {
  background-color: #1890ff;
  color: white;
  border-color: #1890ff;
}
.ghost-button.btn-success:hover {
  background-color: #86b463;
  color: white;
  border-color: #86b463;
}
.tabs {
  background-color: #e5e5e5;
  padding: 21px 0 0 16px;
  display: flex;
}
.tab {
  color: #393e48 !important;
  font-weight: 500;
  background-color: #fff;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  box-shadow: inset 0px -8.5px 8px -9px #c4c4c4;
  text-transform: uppercase;
}
.tab-selected {
  color: #393e48 !important;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  background-color: #f8f9fb !important;
  border-bottom-color: #f8f9fb !important;
  text-transform: uppercase;
  font-weight: 700;
}
.patients-tab-content {
  background-color: #f8f9fb;
}
.tag-icon-round {
  clear: both;
  float: left;
  justify-content: center;
  margin: 2px;
  padding: 7px;
}

/* Checkpoint */
.container-with-padding {
  padding: 20px;
}

.bg-light-gray {
  background: var(--light-gray) !important;
}

.cursor-pointer {
  cursor: pointer;
}

.height-auto {
  height: auto !important;
}

.unstyled-button {
  background: transparent;
  border: none;
}
@media (max-width: 1440px) {
  .username-label {
    margin-top: 10%;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #42dab4;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border: 1.5px solid #cfd6e2;
  background-color: #fff;
}

.btn-vytrac {
  border-radius: 5px;
  background: var(--light-gray);
  border: none;
  color: var(--black-text);
  text-transform: uppercase;

  line-height: 20px;
  padding: 5px 15px;
  font-size: 11px;
  font-weight: 700;
}

.btn-vytrac:hover {
  background: var(--dark-blue);
  color: var(--white);
  cursor: pointer;
}

.image-circle-cropper-sm {
  width: 25px;
  height: 25px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
}
.overflow-visible {
  /* blame bootstrap */
  overflow: visible !important;
}

.border-red {
  border-color: red !important;
}

.vytrac-input {
  background: var(--light-gray);
  border: none;
  min-height: 31px;
}

.vytrac-white-input {
  background: var(--white);
  border: 1px solid var(--gray-border);
}

.fit-content {
  max-height: fit-content;
}

@media (max-width: 1440px) {
  .dashboard-menu {
    width: 200px;
  }

  .dashboard-user-thumb-container {
    height: 200px;
  }
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #fff !important;
  border-color: #cfd6e2 !important;
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #42dab4 !important;
}
