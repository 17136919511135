.crossButton {
  visibility: hidden;
}
.crossButton::before {
  content: url('/src/assets/icons/cross.png');
  display: inline-block;
  visibility: visible;
  transform: scale(0.5);
  cursor: pointer;
}
