.container {
  display: flex;
  flex-direction: column;
  padding: 19px 22px;
  justify-content: space-between;
}
.title {
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
}
.categories {
  font-size: 12px;
}
.categories > span {
  margin-left: 10px;
  margin-right: 10px;
}

.categories > span:first-child {
  margin-left: 0;
}

.categories > span:last-child {
  margin-right: 0;
}

.horizontalLine {
  margin: 0;
  border: none;
  border-top: 1px solid #efeff0;
}
