.vacationContainer{
  background: #fff;
}
.container {
  display: flex;
  justify-content: space-between;
  padding: 5px 20px 0 20px;
  min-height: 46px;
}
.text {
  font-size: 13px;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.title {
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 100px;
  white-space: nowrap;
}
.columnTitle {
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.content {
  display: flex;
  width: 100%;
  align-items: center;
}

.rowContainer {
  display: flex;
  padding: 5px 20px 0 20px;
  min-height: 46px;
}
.columnContainer {
  display: flex;
  flex-direction: column;
  padding: 5px 20px 0 20px;
  min-height: 46px;
}
.datePickerContainer {
  width: 100%;
  display: flex;
  padding-left: 10px;
}
.datePickerInput {
  font-size: 12px !important;
}
.outgoingMessageContainer {
  padding: 10px 20px 20px 20px;
}
.outgoingMessageTitle {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 10px;
}
.outgoingMessage {
  font-size: 12px;
  font-weight: 500;
}
.outgoingMessageInput {
  border: 1px solid #e7eaed;
  width: 100%;
  height: 35px;
  border-radius: 3px;
  padding: 10px;
}
