.content {
  width: 100%;
  overflow: auto;
  overflow-x: hidden;
  padding-right: 8px;
  height: 28.2em;
}
.body {
  padding: 20px;
  padding-right: 8px;
  overflow: auto;
}
