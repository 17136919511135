.container {
  padding: 20px;
  display: flex;
  font-weight: 500;
  font-size: 11px;
  color: #8faac3;
  text-transform: uppercase;
  border-bottom: 1px solid #efeff0;
}
.step {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.step > *:first-child {
  margin-bottom: 10px;
}
.current-step > *:last-child {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  border: 2px solid #8faac3;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}
.current-step > *:last-child > * {
  width: 16px;
  height: 16px;
  background-color: #8faac3;
  border-radius: 100%;
}
.empty-step > *:last-child {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  border: 2px solid #e7eaed;
  background-color: white;
}

.passed-step > *:last-child {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  border: 2px solid #86b463;
  background-color: #86b463;
  background-image: url('assets/icons/check.svg');
}
.divider {
  flex-grow: 1;
  position: relative;
}
.divider::after {
  content: '';
  position: absolute;
  top: 72%;
  left: -25%;
  border-top: 2px solid #e7eaed;
  width: 140%;
  z-index: -1;
}
.passed::after {
  border-top-color: #86b463 !important;
}

@media screen and (max-width: 992px) {
  .divider::after {
    left: -70%;
    width: 214%;
  }
}
