.optionsContainer {
    position: absolute;
    z-index: 1;
    left: 0px;
    top: 50px;
    width: 200px;
    padding: 10px;
    background-color: #F6F7FA;
    box-shadow: 0px 5px 20px rgba(183, 197, 209, 0.4);
}

.optionsInput {
    border: none;
    border-bottom: 1px solid #7D98B7;
    width: 100%;
    height: 30px;
    background: inherit;
    font-size: 13px;
    padding: 0px 5px
}

.optionList {
    display: flex;
    flex-direction: column;
    padding: 5px 0px
}

.optionRow {
    padding: 0px 5px;
    height: 25px;
    display: flex;
    align-items: center;
    transition: background-color 0.1s ease-out;
}

.optionRow:hover {
    background-color: #CFD6E2;
}