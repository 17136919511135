.filter-btn {
  border: 1px solid #efeff0 !important;
  border-radius: 3px;
  display: flex;
  align-items: center;
  padding: 4px 0;
  font-size: 11px;
  cursor: pointer;
  background: none;
}

.filter-count {
  width: 18px;
  height: 18px;
  background-color: #e7eaed;
  border-radius: 10rem;
}

.filter-btn > * {
  margin: 0 6.5px;
}
