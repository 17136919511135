.broadcast-item {
  background-color: var(--light-gray);
  padding: 13px 14px 13px 20px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.broadcast-item > *:first-child {
  margin-right: 3%;
}

.text-container {
  margin-right: 0;
  max-width: 85%;
}

.broadcast-title {
  font-size: 13px;
  line-height: 15.6px;
}
.broadcast-text-container {
  text-overflow: ellipsis;
  overflow: hidden;
}
.broadcast-text {
  display: inline;
  white-space: nowrap;
  font-size: 12px;
  line-height: 14.4px;
}
