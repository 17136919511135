.main {
  padding-left: 20px;
  padding-top: 20px;
  border-top: 1px solid #efeff0;
}

.title {
  text-transform: uppercase;
  font-size: 13px;
  margin-bottom: 24.2px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  padding-right: 0.5rem;
}

.valueContainer {
  font-size: 12px;
  border: none;
}

.main tr > td:first-child {
  font-weight: 500;
}

.identifier {
  vertical-align: middle !important;
}
