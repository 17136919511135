.field {
  max-width: 120px;
  text-overflow: ellipsis;
  border: none;
}

.error {
  color: #f5485c;
}
.error::placeholder {
  color: #f5485c;
}
