#add-rpm-action-btn {
    width: 100%;
    border-radius: 3px;
    text-transform: uppercase;
    border: 1px solid #1890ff;
    background-color: white;
    color: #1890ff;
    font-size: 11px;
    padding-top: 9.5px;
    padding-bottom: 9.5px;
    cursor: pointer;
}

#add-rpm-action-btn:hover {
    background-color: #1890ff;
    color: white;
    border: 1px solid white;
}