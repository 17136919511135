.patients-documents-header-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.patients-documents-header-container div:first-child {
  display: flex;
  align-items: center;
}

.patients-documents-header-container button {
  border: none;
  background: none;
  margin: 0 5px;
}

.patients-documents-header-container button:hover {
  cursor: pointer;
}

.btns-container {
  display: flex;
}
