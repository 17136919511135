.backContainer {
  margin: 20px 0 0 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start !important;
}
.backContainer > *:first-child {
  margin-right: 20px;
}
.controls {
  margin: 20px;
  height: 100%;
  display: flex;
}
.modalFooter {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.button {
  padding: 0 20px;
  height: 25px;
}
.modalBody {
  padding: 20px;
  font-size: 13px;
}
