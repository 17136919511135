.calendar {
    font-size: 0.6vw;
    width: 100%;
    box-shadow: none;
    border-radius: 0px;
}

.calendarTitle {
    padding: 0 20px;
    font-weight: 500;
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: #fff;
    font-size: 13px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.calendarContainer {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    /* display: flex;
    flex-direction: column;
    height: auto; */
}

.shadow {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.todayButton {
    font-size: 12px;
    font-weight: 500;
    color: #1890ff;
    cursor: pointer;
    margin: 15px 10px 15px 10px;
}

.todayButton:hover {
    text-decoration: underline;
}