.regular-font-size {
  font-size: 12px;
  line-height: 14.4px;
  font-weight: 400;
}

.patientName {
  font-size: 13px;
  line-height: 15.6px;
  font-weight: 500;
  color: #1890ff;
}

.patientName:hover {
  text-decoration: underline;
}

.main td {
  vertical-align: middle !important;
}
.name {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
