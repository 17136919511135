.week-day-separator {
  height: 1px;
  background: var(--gray-border);
}

.week-day-separator-label {
  color: var(--gray-low-risk);
}

.week-range-item {
  width: 5px;
}

.week-range-item:hover {
  border: 1px solid var(--light-blue-05);
}
