.main {
  width: 48%;
  height: 30px;
  background-color: #f8f9fb;
  border-radius: 3px;
  margin: 5px 0;
  padding: 0 10px;
  border-left: 3px solid #e1e6f1;
  cursor: grab;
  cursor: -webkit-grab;
  cursor: -moz-grab;
}
