/* .container{
  position: relative;
  height: 34px;
  text-align: center;
}
.line{
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  position: relative;
}
.container::before{
  content: '';
  position: absolute;
  top: 50%;
  border-top: 1px solid black;
  background: black;
  transform: translateY(-50%);
} */
/* .container{
  text-align: center;
  margin: 20px;
  position: relative;
}
.links{
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  position: relative;
}
.wrap:before{
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  border-top: 1px solid black;
  background: black;
  width: 100%;
  transform: translateY(-50%);
}
.answer {
  width: 20px;
  height: 20px;
  background: red;
} */
.container {
  text-align: center;
  margin: 0 20px;
  height: 34px;
  position: relative;
}
.line {
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
}
.line > * {
  margin-right: 20px;
}
.line > *:nth-child(2) {
  margin-left: 20px;
}
.line > *:last-child {
  margin-right: 0;
}
.horizontalLine {
  border: 0;
  height: 1px;
  background: #efeff0;
  position: absolute;
  top: 50%;
  width: 100%;
  margin: 0;
  z-index: -1;
}
.answer {
  width: 13px;
  height: 13px;
  border-radius: 100%;
  background: white;
  border: 1.5px solid #f5485c;
}
