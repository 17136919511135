.audio {
  margin: 0 !important;
  padding: 0 10px 0 0 !important;
  max-width: 100px;
}
.playButton {
  position: absolute;
  z-index: 99;
  left: -60px;
  bottom: -7px;
}
