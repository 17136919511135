.card {
  background: var(--light-gray);
  padding: 13px 20px;
}

.card:hover .action-buttons {
  opacity: 100%;
  transition: opacity 300ms ease;
}

.card .action-buttons {
  opacity: 0%;
}
