.microphone {
  width: 10.3px;
  height: 14px;
  margin-right: 10px;
}

.video {
  width: 14px;
  height: 10px;
  margin-right: 10px;
}

.text {
  width: 14px;
  height: 11px;
  margin-right: 10px;
}

.value {
  font-size: 12px;
  font-weight: 400;
}
