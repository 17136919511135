.main {
  display: flex;
  align-items: center;
  padding: 14px 22px;
}

.title {
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  padding-right: 20px;
  color: #8faac3;
}
