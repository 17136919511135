.title {
  margin-top: 30px;
  color: #393e48;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
}
.description {
  margin-top: 15px;
  color: #393e48;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
}
.reward {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.reward > *:first-child {
  font-size: 20px;
  font-weight: 700;
  color: #8faac3;
  line-height: 24px;
}
.reward > *:last-child {
  color: #1890ff;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
}
.carousel > a > span {
  background-image: url('/src/assets/icons/arrow_carousel.png');
}
.carousel > a:last-of-type > span {
  transform: rotateY(180deg);
}
