.title {
  font-size: 13px;
  font-weight: 500;
}
.subtitle {
  font-size: 11px;
  font-weight: 400;
}
.container {
  padding: 18px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cross {
  cursor: pointer;
}
