.children-container,
.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.content {
  background-color: #f7f7f9;
  max-width: calc(100% - 230px);
}

@media (max-width: 1440px) {
  .content {
    max-width: calc(100% - 200px);
  }
}
