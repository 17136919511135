.footer {
  display: flex;
  justify-content: flex-end;
  padding: 17px 21px;
  background-color: white;
}
.main {
  display: flex;
  justify-content: space-between;
  background-color: white;
  border-bottom: 1px solid #efeff0;
  padding: 16px 20px 13px 21px;
}

.main span {
  font-size: 13px;
  line-height: 15.6px;
  font-weight: 400;
}
