.header-container {
  background: var(--light-gray);
  border-bottom: 1px solid var(--gray-border);
  padding: 0.81rem;
  color: var(--gray-low-risk);
  position: absolute;
  font-weight: 500;
}

.arrow-left {
  position: absolute;
  top: 0;
  left: 0;
}

.arrow-right {
  position: absolute;
  top: 0;
  right: 0;
  margin: auto;
}


.arrow-left:hover,
.arrow-right:hover {
  cursor: pointer;
}

.current-date {
  font-size: 16px;
  color: black;
  background-color: #E7EAED;
  font-weight: 700;
}