.dateCellContainer {
  border-top: 1px solid #efeff0;
  border-right: 1px solid #efeff0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-clip: border-box;
  background-color: white;
}
.dateCell {
  height: 100%;
  width: 100%;
}
