.container {
  display: grid;
  padding: 20px 0;
  gap: 20px;
  grid-template-columns: 1fr 1fr 1fr;
  overflow-x: auto;
}
.container > * {
  margin-bottom: 20px;
}
