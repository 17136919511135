.todo {
  padding: 10px 10px 10px 25px;
  min-height: 47px;
}
.todo > *:first-child,
.todo > *:first-child > *:first-child {
  min-height: 27px;
}
.text {
  font-size: 13px;
  align-items: center;
  display: flex;
  font-weight: 500;
}
.pickerInput {
  height: 100%;
  line-height: 100%;
  font-size: 11px;
  position: relative;
  left: -100px;
}
.pickerInput > input {
  background-color: var(--light-gray);
}
.pickerWrapper {
  display: flex;
  align-items: center;
  margin-right: 20px;
  left: 100px;
  z-index: 101;
}
.button {
  cursor: pointer;
  margin: 15px 0;
  color: #1890ff;
  border: none;
  background-color: transparent;
  margin: 0 20px;
  font-size: 12px;
  font-weight: 500;
}
.button:disabled {
  cursor: auto;
  color: #85c1fa;
}

.loadingToDos {
  position: absolute;
  left: calc(50% - 15px);
  text-align: center;
  top: 90px
}