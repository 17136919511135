.personal-information-body-container > div {
  border-bottom: 1px solid #efeff0;
}

.resetPassword {
  background-color: #EFEFF0;
  color: #393E48;
  font-size: 11px;
  text-transform: uppercase;
  border-radius: 3px;
  padding: 0px 10px;
  height: 25px;
  border: none;
}

.resetPassword:hover{
  background-color: #d7d7d8;
}