.main {
  text-transform: capitalize;
  color: #1890ff;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.4px;
  margin-left: 10px;
  cursor: pointer;
}

.main:hover {
  text-decoration: underline;
}
