.dayPickerInput {
  border: 0;
  font-size: 13px;
  text-align: left;
  padding: 0;
  font-weight: 400;
  overflow: hidden;
  cursor: pointer;
  width: 100%;
}

.hourContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem 3.2rem 0 2rem;
  margin: 0 0 1rem 0;
  font-size: 13px;
  box-shadow: 0px -1px 0px #e7eaed;
  height: 100%;
}
.numberInput {
  width: 35px;
  height: 35px;
  border: none;
  font-size: 13px;
}

.textCenter {
  text-align: center;
  line-height: 2.3rem;
}

.selector {
  text-align: center;
  line-height: 2.3rem;
  width: 40px;
  height: 35px;
  cursor: pointer;
  transition: background 0.5s ease, color 0.5s ease;
}
.selectedSelector {
  background: #393e48;
  color: #fff;
  border-radius: 3px;
  text-align: center;
  line-height: 2.3rem;
  width: 40px;
  height: 35px;
  cursor: pointer;
  transition: background 0.5s ease, color 0.5s ease;
}
.calendar {
  border: 1px solid #e7eaed;
}

.calendarIcon {
  visibility: hidden;
  margin-bottom: 0;
}
.calendarIcon::after {
  content: url('/src/assets/icons/calendar_transparent.png');
  display: inline-block;
  align-items: center;
  visibility: visible;
  cursor: pointer;
  margin-top: 0.1rem;
  margin-right: 0.6rem;
}
.calendarIcon[disabled]::after {
  cursor: default;
}
