.rowContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
    margin: 1rem 0;
}

.select,
.userSelect {
    width: 220px;
    height: 35px;
    display: flex;
    align-items: center;
}

.typeSelect {
    text-transform: capitalize;
}

.userSelect{
    height: 50px;
    background-color: #f8f9fb;
    border-radius: 3px;
    padding: 0px 15px
}

.providerImg{
    margin-right: 10px;
    margin-left: 0px;
}

.pickerContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 220px;
    height: 35px;
    background-color: #f8f9fb;
    padding: 0px 10px;
    border-radius: 5px;
}

.pickerInput {
    background: inherit;
    width: 100%
}

.boxShadowTop {
    box-shadow: 0px -1px 0px #e7eaed;
}

.textArea {
    background-color: #F8F9FB;
    border-radius: 3px;
    padding: 10px
}