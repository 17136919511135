.actions {
  display: flex;
  justify-content: space-between;
  width: 50px;
}
.tagItem {
  display: inline-block;
  cursor: pointer;
}
.container {
  margin: 20px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}
.row {
  border: 0 !important;
}
.header {
  display: flex;
  font-size: 13px;
  font-weight: 500;
  padding: 15px 20px;
  justify-content: space-between;
}
.search {
  margin-right: 10px;
}
.rpmTemplateActions {
  display: flex;
  font-size: 11px;
  height: 25px;
}
.rpmTemplateActions button {
  padding: 0 10px;
  height: 25px;
}
.disabledRows {
  /* background-color: red; */
  opacity: 0.5;
}