.breadCrumbsContainer {
  border-bottom: 1px solid #e7eaed;
  font-size: 13px;
  display: flex;
  justify-content: space-between;
}
.buttonContainer {
  padding: 5px;
  display: flex;
  align-items: center;
}
