.element {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 45px;
  padding: 15px 10px 15px 20px;
}
.name {
  color: #1890ff;
  font-weight: 600;
  font-size: 12px;
  display: flex;
  cursor: pointer;
}
.name > *:first-child {
  margin: auto 20px 1px auto;
}
.usedFor {
  height: 18px;
  font-size: 11px;
  padding: 3px 11px;
  display: flex;
  align-items: center;
  border: 1px solid #cfd6e2;
  margin: 0;
}
