.calendarControl {
  padding: 0;
  background: #fff;
}

.templateData {
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
  font-size: 12px;
  font-weight: 500;
  margin: 15px;
}
.templateDataInput {
  width: 220px;
  background-color: #F8F9FB;
}
.templateData label {
  display: flex;
  align-items: center;
  margin: 0;
  height: 35px;
}

.templateData > * {
  margin-bottom: 10px;
}
.templateData > *:last-child {
  margin-bottom: 0;
}

.calendarControl > div:first-child {
  padding: 13px 15px;
}

.calendarControl > div:first-child > * {
  margin-right: 20px;
}

.dateSelectorContainer {
  padding: 30px 15px 15px 15px
}