.user {
  color: var(--bright-blue);
}
.row {
  border-bottom: 3px solid #efeff0 !important;
}
.header > th {
  padding: 0;
}
.row > td {
  padding: 0;
  height: 50px;
}
