.email-setting-card {
  border: none;
}

.toolbar {
  background-color: var(--light-gray) !important;
}

.subcard {
  background: var(--white);
  min-height: 40px;
}

.editor {
  min-height: 400px;
  overflow: visible;
  background: var(--white);
  padding: 1.5rem;
}

.form-name-input {
  border: 1px solid var(--gray-border-2);
  min-height: 31px;
}

.form-url {
}
