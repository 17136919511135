.main {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.link {
  font-weight: 500;
  font-size: 12px;
  line-height: 14.4px;
  color: #1890ff;
  cursor: pointer;
}

.link:hover {
  text-decoration: underline;
}
