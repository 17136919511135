.buttonsContainer {
  display: flex;
}
.buttonsContainer > * {
  margin-right: 10px;
}
.buttonsContainer > *:last-child {
  margin-right: 0;
}
.backContainer {
  margin: 20px;
  display: flex;
  align-items: center;
  max-width: 160px;
}
.backContainer > *:first-child {
  margin-right: 20px;
}
.controls {
  margin: 20px;
  height: calc(100vh - 180px);
  display: flex;
}

.modalFooter {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.button {
  padding: 0 20px;
  height: 25px;
}
.modalBody {
  padding: 20px;
  font-size: 13px;
}

.graphContainer {
  background-color: white;
  border-radius: 3px;
}