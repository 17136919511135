.main {
  overflow-x: auto;
  background-color: white;
}

.row {
}

.row > * {
  border-right: #efeff0 1px solid;
  border-bottom: #efeff0 1px solid;
}

.patientDetail {
  white-space: nowrap;
  padding-top: 8px;
  padding-left: 19px;
  padding-right: 19px;
}
