.container {
  background-color: white;
  border-radius: 3px;
}
.body input {
  margin-top: 10px;
  height: 35px;
  padding-left: 10px;
  background-color: #F8F9FB;
}


.tags {
  display: flex;
  margin-top: 10px;
}
.tags > * {
  display: inline-block;
}
