.field {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.field label {
  flex-basis: 150px;
  white-space: nowrap;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  margin: 0;
}

.field > * {
  flex-grow: 1;
}
.field input {
  width: 100%;
  background-color: var(--light-gray);
  padding-left: 10px;
  height: 35px;
  border-radius: 5px;
  font-size: 13px;
  line-height: 14.5px;
}
