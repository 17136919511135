.form-label {
  flex-basis: 15%;
  line-height: 31px;
  white-space: nowrap;
}
.form-label-short {
  flex-basis: 5%;
  line-height: 31px;
  white-space: nowrap;
}
.add {
  color: #1890ff;
  padding: 15px 20px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 11px;
}
.row {
  border-bottom: 1px solid #efeff0;
}

.date-picker-container {
  background-color: var(--light-gray);
  border-radius: '1rem';
}
