.timeLabel {
  border-top: 1px solid #efeff0;
  border-right: 1px solid #efeff0;
  width: 40px;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  font-size: 11px;
  font-weight: 500;
}
