.tab {
  border: none !important;
  color: #8faac3 !important;
}
.tabs {
  padding-bottom: 10px !important;
}
.tab-active {
  border: none !important;
  border-bottom: 2px solid #393e48 !important;
  color: #393e48 !important;
}
.tab-content {
  padding: 10px 10px 0px 10px;
  margin-right: 10px;
  max-height: 175px;
  overflow: auto;
}
.messageContainer > * {
  padding-top: 20px;
}
.messageContainer > *:first-child {
  padding-top: 0;
}
.message {
  flex-direction: row-reverse !important;
  justify-content: start;
  background-color: #f8f9fb;
  border-radius: 5px;
  padding: 7px;
  align-items: center;
}
.messageTime {
  color: #8faac3;
  margin-right: 10px;
}
.data {
  padding: 0 10px;
  border-radius: 20px;
  font-size: 11px;
  font-weight: 400;
}
.bubble {
  display: flex;
  align-items: center;
}
.bubble > *:first-child {
  margin-left: 0 !important;
}
