.rowContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.button {
  width: 80px;
  padding-top: 9.5px;
  padding-bottom: 9.5px;
}
