#add-rpm-action-btn {
  width: 100%;
  border-radius: 3px;
  text-transform: uppercase;
  border: 1px solid #1890ff;
  background-color: white;
  color: #1890ff;
  font-size: 11px;
  padding-top: 9.5px;
  padding-bottom: 9.5px;
  cursor: pointer;
}

#add-rpm-action-btn:hover {
  background-color: #1890ff;
  color: white;
  border: 1px solid white;
}

.calendar-control .red {
  color: #f75f70;
}

.calendar-control .tag-item {
  margin: 2.5px 5px 2.5px 0;
  display: flex;
  padding-left: 5px;
  padding-right: 11px;
  align-items: center;
}

.calendar-control > div:first-child {
  padding: 13px 10px;
}
.calendar-control > div:first-child > * {
  margin-right: 20px;
}
