.patientCommunicationsContainer {
  padding: 20px;
  min-width: 150px;
}

.topIconContainer {
  display: flex;
  padding-bottom: 8px;
  padding-top: 8px;
  justify-content: space-between;
}

.bottomIconContainer {
  display: flex;
  justify-content: space-between;
  padding-top: 8px;
  padding: 8px 5px 0px 5px;
}

.comsButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 80px;
  width: 25px;
  border: none;
  background: inherit;
  transition: background-color 0.1s ease-in;
}

.comsButton:hover {
  background-color: #DFDFDF
}

.comsButton:disabled:hover {
  background-color: inherit;
}