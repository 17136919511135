.main {
  padding: 20px;
  margin: 0;
  max-width: calc(100vw - 230px);
  overflow: hidden;
}
@media (max-width: 1440px) {
  .main {
    max-width: calc(100vw - 200px);
  }
}
