.dateLabel {
  display: flex;
  justify-content: center;
  gap: 3%;
  align-items: center;
  font-size: 11px;
  font-weight: 500;
  color: #8faac3;
  min-height: 40px;
}
