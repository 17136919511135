/* stolen from SO */
/* headlines with lines */
.decorated {
  overflow: hidden;
  text-align: center;
}
.decorated > span {
  position: relative;
  display: inline-block;
}
.decorated > span:before,
.decorated > span:after {
  content: '';
  position: absolute;
  top: 50%;
  border-bottom: 1px solid var(--gray-border);
  width: 591px; /* half of limiter*/
  margin: 0 20px;
}
.decorated > span:before {
  right: 100%;
}
.decorated > span:after {
  left: 100%;
}
