.datePicker {
  background-color: #fff;
  font-weight: 500;
  font-size: 11px;
  left: 1rem;
  position: relative;
}
.datePickerWrapper {
  left: 8rem;
  position: absolute;
  z-index: 999;
}
