.popup {
  position: absolute;
  z-index: 100;
  background-color: white;
  left: 400px;
  top: 0;
  cursor: default;
  min-height: 94px;
  max-width: 780px;
  min-width: 260px;
  overflow-x: auto;
  display: flex;
  flex-grow: 1;
}
