.rowContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0;
  margin: 1rem 0;
  height: 35px
}

.select {
  width: 220px;
  height: 35px;
  display: flex;
}