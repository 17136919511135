.iconButton{
    cursor: pointer;
    margin: 0px 10px;
}

.valueSelector {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
    justify-content: space-between;
}