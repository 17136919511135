.card {
  min-height: 600px;
  background-color: white;
  border-radius: 5px;
  margin-bottom: 0 !important ;
  display: flex;
  flex-direction: column;
}
.chart {
  height: calc(100% - 50px);
  position: relative;
  width: 100%;
}
