.flex-basis-10 {
  flex-basis: 10%;
}
.flex-basis-5 {
  flex-basis: 5%;
}

.modal-content-container {
  max-height: 400px;
  overflow-y: scroll;
  padding: 20px;
}

.modal-content-item {
  background: var(--light-gray);
  padding: 14px 10px;
}

.modal-content-alert-count {
  color: var(--gray-low-risk);
}
