.daySelectorContainer {
    padding: 5px;
    position: absolute;
    z-index: 99;
    right: 15%;
    display: block;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 5px 20px rgba(183, 197, 209, 0.6);
}

.numberContainer{
    border-radius: 80px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    transition: background-color ease-in 0.2s;
}

.numberContainer:hover {
    background-color: #F6F7FA;
}

.input {
    padding-left: 10px;
    height: 35px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}