.field {
  font-size: 12px;
  display: flex;
}

.label {
  flex-basis: 30%;
}

.pills-container {
  background: var(--light-gray);
  min-height: 35px;
}

.error {
  color: red;
  font-size: 12px;
  margin: -5px 0 0 0;
}
