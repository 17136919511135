.main {
  display: flex;
  border: 1px solid #e7eaed;
  box-shadow: inset 1px 1px 1px 3px rgba(55, 70, 84, 0.05);
  border-radius: 3px;
  padding: 10px;
  padding-right: 17px;
  cursor: text;
  line-height: 0;
}

.input {
  flex-grow: 1;
  font-size: 12px;
  border: none;
}
input:disabled {
  background-color: white !important;
}

.button {
  background: none;
  border: none;
  font-size: 12px;
  line-height: 14.4px;
  font-weight: 500;
  color: #1890ff;
  cursor: pointer;
  padding: 0 1rem;
}

.button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.input-outer-container {
  border: 1px solid #e7eaed;
  box-shadow: inset 1px 1px 1px 3px rgba(55, 70, 84, 0.05);
  border-radius: 3px;
}

.input-inner-container {
  background-color: white;
}

/* Copied from github */

.mentions {
  flex-grow: 1;
}

.mentions--singleLine .mentions__control {
  line-height: initial;
}
.mentions--singleLine .mentions__highlighter {
  padding: 0.3rem;
  border: 1px solid transparent;
}
.mentions--singleLine .mentions__input {
  padding: 0.3rem;
  border: 1px solid transparent;
}

.mentions--multiLine .mentions__control {
  font-family: monospace;
  font-size: 14pt;
}
.mentions--multiLine .mentions__highlighter {
  border: 1px solid transparent;
  padding: 9px;
  min-height: 63px;
}
.mentions--multiLine .mentions__input {
  border: 1px solid silver;
  padding: 9px;
  outline: 0;
}

.mentions__suggestions__list {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 10pt;
}

.mentions__suggestions__item {
  padding: 5px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.mentions__suggestions__item--focused {
  background-color: #cee4e5;
}

.mentions__highlighter .mentions__mention {
  font-weight: 700;
}

.mentions__mention {
  position: relative;
  z-index: 1;
  color: black;
  text-shadow: 1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white, -1px -1px 1px white;
  pointer-events: none;
}

.mentions__hashtag {
  position: relative;
  z-index: 1;
  color: var(--bright-blue);
  text-shadow: 1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white, -1px -1px 1px white;
  pointer-events: none;
}
