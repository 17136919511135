.backContainer {
  margin: 20px 0 0 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start !important;
}
.backContainer > *:first-child {
  margin-right: 20px;
}
.container {
  padding: 20px;
}
.name {
  color: #1890ff;
  font-size: 12px;
  cursor: pointer;
}
