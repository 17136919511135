.patient-reports-table-container {
  max-height: 50vh;
  overflow: auto;
}

.patient-reports-table-container th {
  position: sticky;
  top: -1.5rem;
  background-color: white;
  white-space: auto;
}

.patient-reports-card {
  background-color: white;
  border-radius: 5px;
  overflow: hidden;
}

.pill:hover {
  opacity: 0.8;
}

.pills-container {
  gap: 0.5rem;
}
