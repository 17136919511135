.horizontalLine {
  border-color: #efeff0;
  margin: 0;
  padding: 0;
  width: 100%;
}
.horizontalLineContainer {
  padding: 0 12px;
  width: 100%;
}
.time,
.messageCount {
  color: #8faac3;
  font-size: 11px;
  font-weight: 500;
  display: flex;
  white-space: nowrap;
}

.messageContainer > * {
  padding-top: 20px;
}
.message {
  flex-direction: row-reverse !important;
  justify-content: start;
  background-color: #f8f9fb;
  border-radius: 5px;
  padding: 7px;
  align-items: center;
}
.messageTime {
  color: #8faac3;
  margin-right: 10px;
}
.data {
  padding: 0 10px;
  border-radius: 20px;
  font-size: 11px;
  font-weight: 400;
}
.bubble {
  display: flex;
  align-items: center;
}
.bubble > *:first-child {
  margin-left: 0 !important;
}
.status {
  font-size: 12px;
  font-weight: 400;
  padding-top: 20px;
}
