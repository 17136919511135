.user-bubble {
  height: 52px;
  width: 52px;
  font-size: 20px;
  margin: 0 !important;
}

.photo-name-container {
  padding: 25px 25px 15px 25px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--gray-border);
  margin-bottom: 13px;
}
.photo-name-container > *:first-child {
  margin-right: 25px !important;
}
.name-container > *:first-child {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
}
.name-container > *:last-child {
  font-size: 13px;
  font-weight: 500;
  color: var(--bright-blue);
  cursor: pointer;
}
.name-container > *:last-child:hover {
  text-decoration: underline;
}

.info {
  padding: 7px 10px 20px 10px;
}

.patient-information {
  white-space: nowrap;
  font-size: 12px;
  padding-right: 10px;
}
.patient-information > * {
  padding: 0 !important;
  flex-grow: 0;
}
