.itemContainer {
    border: 2px solid white;
    padding: 15px 20px 15px 10px;
    cursor: pointer;
    background-color: #F8F9FB;
    align-items: center;
    color: #393E48;
    font-size: 13;
    display: flex;
    justify-content: space-between;
}