.card {
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  background: #fff;
  padding-bottom: 10px;
}
.header {
  height: 50px;
  display: flex;
  justify-content: space-between;
}
.title {
  display: flex;
  align-items: center;
  padding: 0 20px;
  font-size: 13px;
  font-weight: 500;
}
.controls {
  display: flex;
  align-items: center;
  padding-right: 20px;
}
.controls > *:first-child {
  margin-right: 10px;
}
.horizontalLine {
  flex-grow: 1;
  border-color: #efeff0;
  margin: 0;
  padding: 0;
}
.expandArrow{
  background-image: url('/src/assets/icons/expand.png') !important;
  width: 11px;
  height: 11px;
  cursor: pointer;
  margin: 1rem;
}
.satisfactionBar {
  width: 85%;
  margin: 5px;
}
.spanValue {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 250;
  font-size: 16px;
  line-height: 24px;
  color: #393e48;
}
.span {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
}
.body {
  max-height: 150px;
  margin: 1rem 1rem 0 1rem;
}

