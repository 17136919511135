.modal-body {
  padding: 0%;
}

.body-element-container-first,
.body-element-container-last {
  padding: 0;
}
.body-element-container-first {
  padding: 0 20px;
}

.body-element-container-middle {
  padding: 0rem 20px;
  background-color: #f8f9fb;
  box-shadow: 0px -1px 0px #e7eaed;
}
