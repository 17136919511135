.logContainer {
  padding: 20px 0;
}
.messagesContainer {
  padding: 75px 5px 20px 5px;
}
.providerMessage {
  display: flex;
  flex-direction: row-reverse;
  max-width: 100%;
}
.patientMessage {
  display: flex;
  max-width: 100%;
  margin-right: 60px;
}

.encounterId {
  padding: 18px 20px;
}

.searchBox {
  padding: 12px 20px;
}
.timeStampLeft {
  font-size: 11px;
  color: #8faac3;
  display: flex;
}

.timeStampRight {
  font-size: 11px;
  color: #8faac3;
  justify-content: flex-end;
  display: flex;
}
.encounterEnded {
  display: flex;
  padding: 20px 0;
}

.encounterEndedText {
  color: #8faac3;
  font-weight: 500;
  width: 180px;
  text-align: center;
  font-size: 11px;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.horizontalLine {
  flex-grow: 1;
  border-color: #efeff0;
}
