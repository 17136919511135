.gap-lg {
  gap: 1.25rem;
}

.gap-md {
  gap: 1rem;
}

.gap {
  gap: 0.75rem;
}

.gap-sm {
  gap: 0.25rem;
}
