.container {
  padding: 10px;
  height: 100%;
  cursor: pointer;
  border-right: 1px solid var(--gray-border);
  min-width: 250px;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.alertContainer{
  border-bottom: 1px solid var(--gray-border);
  padding-bottom: 10px;
}
.lowerAlertInfo {
  padding-top: 10px;
  font-size: 12px;
  font-weight: 400;
  display: flex;
}
