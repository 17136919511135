.atUser {
  color: #42dab4;
  font-weight: 700;
}
.yellowTag {
  background-color: #fff7e1;
  display: inline;
  color: #af7608;
  border: none;
}

.normalTag {
  display: inline;
  border: none;
}
