.link {
  background: none;
  border: none;
  font-weight: 500;
  font-size: 13px;
  line-height: 15.6px;
  color: #1890ff;
  cursor: pointer;
  text-transform: capitalize;
}

.link:hover {
  text-decoration: underline;
}
.nav {
  background: #F8F9FB;
}
.horizontalLine {
  border-color: #e7eaed;
  margin: 0;
  padding: 0;
}
