.close ::before {
  content: url('/src/assets/icons/cross.png');
  visibility: visible;
  color: #8faac3;
  font-weight: 500;
  font-size: 25px;
  width: 13px;
  height: 13px;
}

.sr-only::before {
  visibility: hidden;
}

.close {
  visibility: hidden;
  position: absolute;
  right: 32px;
  top: 10px;
  width: 13px;
  height: 13px;
}

.modal-title {
  font-size: 13px;
  font-weight: 500;
}
.modal-header {
  padding: 17px auto 17px 20px;
}
.modal-content {
  border: none !important;
  box-shadow: 0px 5px 20px rgba(183, 197, 209, 0.6) !important;
}
