.patientTimelineContainer {
  margin: 20px;
  background-color: white;
  border-radius: 5px;
  min-height: calc(100% - 20px);
  display: flex;
  flex-direction: column;
}

.patientTimelineChartContainer {
  padding-top: 20px;
  position: relative;
  height: 30vh;
}
