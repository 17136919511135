.statusContainer {
    display: flex;
}

.statusCol{
    width: 50%;
    min-width: 610px;
}

@media screen and (max-width: 1500px) {
    .statusContainer {
        flex-direction: column;
    }
    
    .statusCol {
        width: 100%;
    }
}