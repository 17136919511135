.notification-right {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  background-color: attr(data-color);
  border: 1px solid white;
  right: 11px;
  bottom: -7px;
}
.notification-left {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  background-color: attr(data-color);
  border: 1px solid white;
  right: 35px;
  bottom: -7px;
}
.notification-left::before {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 12px;
  font-weight: 600;
  font-size: 9px;
  color: white;
  content: attr(data-content);
}
.notification-right::before {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 12px;
  font-size: 9px;
  font-weight: 600;
  color: white;
  content: attr(data-content);
}
.open-messenger {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  color: #1890ff;
}
.input-container {
  padding: 10px;
}
.input {
  border-radius: 3px;
  box-shadow: inset 0px 0px 1px 3px rgba(55, 70, 84, 0.05) !important;
}

.chat-container {
  border-radius: 3px;
  margin-bottom: 3px;
  margin-top: 3px;
  margin-left: 5px;
}
.chat-item {
  display: flex;
  flex-direction: row;
  padding: 10px 15px 10px 10px;
  align-items: flex-start;
  color: var(--black-text);
  font-size: 13px;
}
