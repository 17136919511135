.title {
    font-size: 13px;
    text-transform: uppercase;
    user-select: none;
}

.circle {
    color: rgb(245, 72, 92);
    border: 1px solid rgb(245, 72, 92);
    align-items: center;
    place-content: center;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;
}
.header {
    display: flex;
    align-items: center;
    gap: 0.4rem;
    /* border-bottom: 1px solid var(--gray-border); */
    /* padding-bottom: 17px; */
}