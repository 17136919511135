.buttonsContainer {
  display: flex;
  gap: 10px;
  padding: 8px 10px 5px 10px;
}

.button {
  padding: 0 10px;
  height: 25px;
  line-height: 0px;
}
