.item {
  background-color: #8faac3;
  height: 30px;
  font-size: 12px;
  display: flex;
  align-items: center;
  cursor: move;
  user-select: none;
  justify-content: space-between;
  color: #fff;
  border-radius: 3px;
}
.item > * {
  margin: 0 10px;
}
