.externalBox {
  border: 1px solid #cfd6e2;
  box-sizing: border-box;
  border-radius: 20px;
  display: flex;
  padding: 5px 10px;
  background-color: #fff;
}
.externalBox > *:first-child {
  margin-right: 10px;
}
.burgerMenu > path {
  fill: #cfd6e2;
}
.burgerMenu > rect {
  stroke: #cfd6e2;
}
.burgerMenuActive > path {
  fill: #8faac3;
}
.burgerMenuActive > rect {
  stroke: #8faac3;
}
.menu {
  position: absolute;
  border: 1px solid #8faac4;
  box-sizing: border-box;
  border-radius: 5px;
  font-size: 12px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  right: -140px;
  z-index: 11;
}
.menu > * {
  padding: 10px 20px;
}
.menu > *:first-child {
  box-shadow: 0 0 0 #e7eaed, 0px 1px 0px #e7eaed;
}
