.login-container {
  height: 100vh;
  width: 100vw;
  background-color: #ffffff;
  filter: drop-shadow(0px 5px 20px rgba(183, 197, 209, 0.6));
  flex-direction: row;
  padding-left: 0px;
  padding-right: 0px;
}
.login-row {
  font-size: 2vw;
  height: 100vh;
  width: 100vw;
}
.login-background-image-gradient {
  background-image: linear-gradient(to bottom right, #96d7cc, #5676a5);
  z-index: -6;
}
.login-background-image {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -5;
}
.login-user-thumb {
  width: 225px;
  height: 225px;
  background-color: #ffffff;
  border-radius: 112px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -4;
  filter: drop-shadow(0px 5px 20px rgba(183, 197, 209, 0.6));
}

.logo-container {
  position: absolute;
  left: 1%;
  bottom: 1%;
}

.login-form {
  padding: 2.5rem;
  background-color: white;
}
