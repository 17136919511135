.item {
  height: 45px;
  margin: 5px 20px;
  font-size: 12px;
  padding-left: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: move;
  user-select: none;
  color: #1890ff;
  background: #F8F9FB;
}
.label{
  margin-right: 10px;
  font-size: 12px;
}
