.container {
    width: 750px;
    display: flex;
    padding: 0.25rem;
  }
  .addProviderContainer {
    position: relative;
    left: 65%;
  }
  .header{
    background-color: #F8F9FB;
    border-bottom: 1px solid #efeff0;
    padding: 1rem;
  }
  .headerTitle{
    font-size: 12px;
  }
  .headerProvider{
    font-size: 12px;
    width: 40%;
  }
  .labelBlue{
    font-size: 12px;
    color: #1890FF;
    cursor: pointer;
  }
  .content {
    display: flex;
    width: 100%;
    align-items: center;
  }
  .input {
    background-color: #f8f9fb;
    width: 100%;
    height: 30px;
  }
  .timeSpentInput{
    background-color: #f8f9fb;
    width: 80%;
    height: 30px;
  }
  .row{
    border-bottom: 1px solid #efeff0;
    padding: 1rem;
  }
  .actions {
    display: flex;
    justify-content: space-between;
    width: 20px;
    height: 20px;
    margin: 0.25rem;
  }
  .select {
    font-size: 12px;
    font-weight: 500;
    padding-left: 10px;
  }