.circle {
    margin-right: 5px;
    border: 1px solid;
    align-items: center;
    place-content: center;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;
}