.container {
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  line-height: 14px;
}
.arrow {
  height: 8px;
  width: 5px;
}
