.innerBox {
  background: #86b463;
  border-radius: 20px;
  height: 26px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  font-size: 12px;
  color: #fff;
}
.innerBox > span {
  min-width: 10px;
}

.button {
  border: none;
  background: none;
}

.ripple {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}
.ripple:after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  background-image: radial-gradient(circle, #000 10%, transparent 10.01%);
  background-repeat: no-repeat;
  background-position: 50%;
  transform: scale(10, 10);
  opacity: 0;
  transition: transform 0.5s, opacity 1s;
}
.ripple:active:after {
  transform: scale(0, 0);
  opacity: 0.2;
  transition: 0s;
}

.switch {
  display: flex;
}
.switch > label {
  display: flex;
  align-items: center;
}
