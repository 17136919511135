.item {
  background-color: var(--light-gray);
  border-radius: 3px;
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  color: #393e48;
  font-size: 12px;
  margin-bottom: 3px;
}
