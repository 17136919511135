.commentContainer {
  padding: 10px 20px;
  background-color: #f8f9fb;
}

.commentInfo {
  padding-right: 12px;
  white-space: nowrap;
}

.actionContainer {
  padding: 10px;
}

.actionButton {
  border: none !important;
}
