.horizontalLine {
  flex-grow: 1;
  border-color: #efeff0;
  margin: 0;
}

.modalFooter {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.button {
  padding: 0 20px;
  height: 25px;
}
.modalBody {
  padding: 20px;
  font-size: 13px;
}
