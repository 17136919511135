.square-checkbox {
  box-sizing: border-box;
  appearance: none;
  background: white;
  outline: 2px solid var(--black-text);
  border: 2px solid white;
  width: 0.75rem;
  height: 0.75rem;
}

.square-checkbox:checked {
  background: var(--black-text);
}

.square-checkbox:focus {
  outline: 2px solid var(--bright-blue);
}
