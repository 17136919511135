.toggle-header {
  background: var(--white) !important;
  border: none;
}
.title-input {
  flex-basis: 40%;
}

.accordion-toggle {
  background: none;
  border: none;
}

.input-toggle-container {
  flex-basis: 90%;
}

.toggle-rotate {
  transition: all 0.25s;
}

.toggle-rotate:focus {
  outline: none;
}

.toggle-rotate-0 {
  transform: rotate(0deg);
}

.toggle-rotate-90 {
  transform: rotate(90deg);
}

.select-min-width {
  min-width: 11rem;
}
