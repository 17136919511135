.notifications-popup {
  background: #fff;
  max-width: 320px;
  top: 100%;
  right: 9%;
  color: #393e48;
  z-index: 99;
  filter: drop-shadow(0px 5px 20px rgba(183, 197, 209, 0.6));
}
.notifications-popup::before {
  position: absolute;
  top: -10px;
  left: 50%;
  content: '';
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;

  border-bottom: 10px solid #fff;
}
.header {
  padding: 17px 120px;
  font-size: 13px;
  font-weight: 500;
  border-bottom: 1px solid #efeff0;
}
.notifications-container > * {
  border-bottom: 1px solid #efeff0;
  padding: 10px 20px;
}
.message {
  font-size: 12px;
  margin-top: 10px;
  line-height: 14px;
}
