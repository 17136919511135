.user-info {
  font-weight: 400;
  font-size: 12px;
  line-height: 14.5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 24px 0 10px;
}
.user-populations {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  padding: 20px 0 10px 10px;
  font-size: 11px;
  font-weight: 500;
}
