.table-main-container {
  padding-left: 20px;
  padding-top: 20px;
}

.table-main-container th:first-child {
  width: 167px;
}
.table-main-container th:nth-child(2),
.table-main-container td:nth-child(2) {
  padding-left: 52px;
}

.table-main-container th {
  font-size: 13px;
}

.table-main-container th:first-child {
  text-transform: uppercase;
}

.table-main-container .form-control {
  font-size: 12px;
}

.table-main-container td {
  vertical-align: middle;
}

.table-main-container td:first-child {
  color: #8faac4;
}
