.error-message {
  color: var(--red-high-risk);
  font-size: 12px;
  padding-bottom: 0.5rem;
}

.question {
  margin-top: 20px;
}
.question > *:first-child {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 600;
}
