.title {
  background-color: var(--light-gray);
  text-transform: uppercase;
  color: #8faac3;
  margin: 0 !important;
  padding: 20px !important;
  width: 100% !important;
  border-radius: 0 !important;
  height: 40px !important;
}
.field {
  height: 35px;
  width: 5vw;
  font-size: 12px;
  font-weight: 400;
  display: flex;
  align-items: center;
  background-color: var(--light-gray);
  margin: 10px 20px;
  border-radius: 3px;
  padding: 10px;
}

.container {
  display: grid;
  gap: 3px 0;
}
.grid-row-wrapper {
  display: contents;
}
