.select {
  font-size: 12px;
  font-weight: 500;
  width: 100%;
}
.content {
  display: flex;
  width: 100%;
  align-items: center;
}
.rowContainer {
  display: flex;
  padding: 5px 20px 0 20px;
  min-height: 46px;
}
.input {
  background-color: #f8f9fb;
  width: 100%;
}

.inputLink {
  background-color: #f8f9fb;
  width: 100%;
  margin: 20px 20px 10px 0px;
}

.title {
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 50%;
}

.marginResourceAddress{
  margin-left: 19px;
}
