.main {
  padding: 19px 19.5px;
}

.title {
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.text {
  padding-top: 10px;
  font-size: 12px;
}
