.email-setting-card {
  border: none;
}

.toolbar {
  background: var(--light-gray);
}

.subcard {
  background: var(--white);
  min-height: 40px;
}

.editor {
  min-height: 400px;
  overflow: visible;
  background: var(--white);
  padding: 0.5rem;
}

.subject-input {
  border: 1px solid var(--gray-border-2);
  min-height: 31px;
}
