.header {
    display: flex;
    justify-content: space-between;
  }
  .title {
    display: flex;
    align-items: center;
    padding: 0 20px;
    font-size: 13px;
    font-weight: 500;
  }
  .controls {
    display: flex;
    align-items: center;
    position: absolute;
    left: 75%;
  }
  .controls > *:first-child {
    margin-right: 10px;
  }
  .arrow {
    margin-top: 3px;
  }
  .horizontalLine {
    flex-grow: 1;
    border-color: #efeff0;
    margin: 0;
    padding: 0;
  }
  .modalBody{
    padding: 0;
  }
  .export{
    font-size: 12px;
    color:#1890FF;
    cursor: pointer;
  }
  .select{
    border:0;
    margin-top: 0.25rem;
  }
  .labelCompare{
    font-size: 12px;
    padding: 0 0.5rem 0 0.5rem
  }
  .checkbox {
    height: 12px;
    width: 12px;
    background-color: #eee;
  }
  .monthCompare{
    margin: 2px;
    font-size: 10px;
    padding-right: 10px;
  }
  .monthCompareContainer {
    position: absolute;
    right: 5%;
    cursor: pointer;
  }