.vytracInputField {
  border: 0;
}
.vytracInputField[type='file'] {
  display: none;
}

.vytracInputFieldContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.customFileUpload[type='file'][value] {
  cursor: pointer;
  color: #1890ff;
  max-width: 180px;
}
