.patientCarePlanHeader{
  display: flex;
  align-items: center;
  width: 100%
}

.patientCarePlanHeader > button {
  background: none;
  border: none;
  color: #1890ff;
  cursor: pointer;
}

.patientCarePlanHeader > button:hover {
  text-decoration: underline;
}

.patientCarePlanHeader > button:focus {
  outline: none;
}

.patientCarePlanHeader > * {
  margin-right: 15px;
}

.patientCarePlanHeader :last-child {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  font-weight: 500;
}
