.container {
  width: 750px;
  display: flex;
  padding: 0.25rem;
}
.swithContainer {
  position: relative;
  left: 72%;
}
.rowContainer {
  display: flex;
  padding: 0px 20px 10px 20px;
  min-height: 46px;
  border-bottom: 3px solid #f7f7f9;
}
.title {
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 20%;
}
.content {
  display: flex;
  width: 100%;
  align-items: center;
}
.input {
  background-color: #f8f9fb;
  width: 100%;
}
.addCode{
  color: #1890ff;
  font-size: 12px;
  margin-left: 1.25rem;
}
.button{
  margin: 0.5rem;
}
