.questionnaireContainer {
  margin: 10px 0px;
}

.answersContainer {
  z-index: 2;
  width: 100%;
  padding: 0px 20px;
}

.answersContainer > div {
  width: 13px;
  height: 13px;
  border: #f5485c 1px solid;
  border-radius: 80px;
}

.lineContainer {
  padding: 6px 0px;
  z-index: 1;
  width: 100%;
}

.viewAnswersButton {
  padding: 10px 20px 0px 20px;

  background: inherit;
  border: none;
  color: #1890ff;
}

.viewAnswersButton:hover {
  text-decoration: underline;
}

.viewAnswersButton:focus {
  outline: none;
}
