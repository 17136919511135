.genericTable thead tr {
  background-color: #f8f9fb;
  text-transform: uppercase;
  color: #8faac3;
}
.genericTable tbody tr:last-child {
  border: 0;
}

.genericTable {
  margin: 0;
}

.genericTable td,
.genericTable th {
  vertical-align: middle !important;
  border-bottom: 3px solid #f8f9fb;
}

.genericTable tr td:first-child,
.genericTable tr th:first-child {
  padding: 13px 0 13px 20px;
}
.genericTable tr td:last-child,
.genericTable tr th:last-child {
  padding: 13px 20px 13px 0;
}
