.confirmation {
  background: var(--green-confirmation-100);
  color: var(--green-confirmation-500);
  border: 1px solid var(--green-confirmation-50);
}

.error {
  background: var(--red-error-100);
  color: var(--red-high-risk);
  border: 1px solid var(--red-error-50);
}

.notification {
  background: var(--blue-notification-100);
  color: var(--blue-notification-500);
  border: 1px solid var(--blue-notification-50);
}
