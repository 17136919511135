.scheduleCointainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
}
.currentScheduleInput {
  height: 35px;
  width: 360px;
  margin-left: 10px;
  padding-left: 10px;
  background-color: #f8f9fb;
  border-radius: 5px;
}
.currentScheduleContainer {
  font-size: 12px;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.currentScheduleLabel {
  font-size: 13px;
  margin: 0 !important;
}
.timeZoneLabel {
  font-size: 13px;
}
