.container {
  background-color: var(--light-gray);
  padding: auto -1rem;
  text-transform: uppercase;
  color: #8faac3;
  font-weight: 500;
  font-size: 11px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  border-bottom: 1px solid #efeff0;
}
.arrow {
  transition: all 0.3s ease-in-out;
}
