.headers {
  display: flex;
  background-color: var(--light-gray);
}
.row > *:first-child {
  flex: 4 1 0;
  padding-left: 20px;
}
.row > *:nth-child(2) {
  flex: 2 1 0;
}

.headers > * {
  text-transform: uppercase;
  color: #8faac3;
  font-weight: 500;
  font-size: 11px !important;
}
.row > * {
  flex: 1 1 0;
}
.row > * {
  min-height: 20px;
  align-items: center;
  display: flex;
  text-overflow: ellipsis;
}
.row {
  padding: 15px 0;
  display: flex;
  font-size: 12px;
}
.imageName {
  font-size: 13px;
}
.reward {
  color: #8faac3;
  font-weight: 700;
  line-height: 10px;
}
.rowContainer > * {
  border-bottom: var(--light-gray) 3px solid;
}

.rowContainer > *:last-of-type {
  border-bottom: none;
}
