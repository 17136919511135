.menu {
  height: 100vh;
  width: 45vw;
  position: fixed;
  transform: translateX(100%);
  overflow-x: hidden;
  top: 0;
  right: 0;
  z-index: 1030;
  background-color: white;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  filter: drop-shadow(0px 2px 20px rgba(133, 142, 151, 0.2));
  display: flex;
  flex-direction: column;
}
.menu > * {
  border-bottom: 1px solid #efeff0;
}
.menu > *:last-child {
  border-bottom: none;
}
.detail {
  flex: 1;
}
.header {
  height: 70px;
}
.footer {
  height: 60px;
}
