.questionnaireItem {
  font-size: 12px;
  color: #1890ff;
  user-select: none;
}
.row {
  cursor: pointer;
}
.header {
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  padding: 15px 20px;
}
.container {
  margin: 20px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}
.questionnaireTemplateActions {
  display: flex;
  font-size: 11px;
  height: 25px;
}
.questionnaireTemplateActions button {
  padding: 0 10px;
  height: 25px;
}
