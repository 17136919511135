.chat-item {
  min-height: 60px;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: var(--light-gray);
  margin-bottom: 3px;
  border-radius: 3px;
  padding: 13px 10px;
  cursor: pointer;
}
.chat-item-current {
  background-color: var(--gray-tags);
}

.conversation-title {
  font-size: 13px;
  line-height: 15.6px;
}

.patient-last-message {
  white-space: nowrap;
  line-height: 14.4px;
}
.chat-info {
  text-overflow: ellipsis;
  overflow: hidden;
}

.notification-right {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  background-color: attr(data-color);
  border: 1px solid white;
  right: 11px;
  bottom: -5px;
}
.notification-left {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  background-color: attr(data-color);
  border: 1px solid white;
  right: 35px;
  bottom: -5px;
}
.notification-left::before {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 12px;
  font-weight: 600;
  font-size: 9px;
  color: white;
  content: attr(data-content);
}
.notification-right::before {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 12px;
  font-size: 9px;
  font-weight: 600;
  color: white;
  content: attr(data-content);
}
