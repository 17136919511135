.card {
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  width: 100%;
  background: #fff;
}
.header {
  height: 50px;
  display: flex;
  justify-content: space-between;
}
.body {
  padding: 10px 0px;
}
.title {
  display: flex;
  align-items: center;
  padding: 0 20px;
  font-size: 13px;
  font-weight: 500;
}
.controls {
  display: flex;
  align-items: center;
  padding-right: 20px;
}
.controls > *:first-child, 
.controls > *:nth-child(2) {
  margin-right: 10px;
}
.horizontalLine {
  flex-grow: 1;
  border-color: #efeff0;
  margin: 0;
  padding: 0;
}
