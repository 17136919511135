.actions {
  color: #1890ff;
  cursor: pointer;
}
.codeName {
  color: #1890ff;
  cursor: pointer;
}
.row {
  background-color: white;
  border-bottom: 3px solid #f7f7f9;
  height: 55px;
}
