.search {
  margin-right: 10px;
}
.button {
  height: 25px;
  padding: 0 10px;
  margin-right: 1rem;
}
.codeCharges{
  width: 67%;
  margin-top: 5px;
}
.horizontalLine {
  border-color: #e7eaed;
  margin: 0;
  padding: 0;
}
.actions {
  display: flex;
  justify-content: space-between;
  width: 30px;
}
.insuranceCompany {
  color: #1890ff;
}
.checkUpdates {
  color: #1890ff;
  margin: 5px 10px 5px 10px;
}
.row {
    background-color: white;
    border-bottom: 3px solid #f7f7f9;
    height: 55px;
  }
