.main {
  padding-right: 64px;
}

.main .form-control {
  width: 120px;
}

.main td:nth-child(2) > .form-control,
.main td:nth-child(3) > .form-control {
  width: 180px;
}
