.checkBoxContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 20px;
  margin: 1rem 0;
  font-weight: 500;
}
.rowContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 20px;
  margin: 1rem 0;
}
textarea {
  width: 100%;
  border: none;
  resize: none;
  overflow: auto;
  line-height: 13px;
  font-weight: 400;
}
