.header {
  border-bottom: 1px solid var(--gray-border);
}

.device-select {
  border-bottom: 1px solid transparent;
}

.device-select:hover {
  border-bottom: 1px solid var(--gray-border);
}

.card-container {
  max-width: 380px;
}

.device-container {
  border: 1px solid var(--gray-border);
}
