.main {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.4px;
  align-items: center;
  padding: 20px;
}

.satisfactionBar {
  margin-left: 10px;
  width: 100%;
}
