.numberContainer {
  font-size: 12px;
  color: #8faac3;
  border-radius: 3px;
  width: 30px;
  height: 20px;
  margin: 1.5px;
  background-color: #e1e6f1;
  text-align: center;
  cursor: pointer;
}
