.appointment {
  /* height: 50px; */
  padding: 20px 0 0 0;
  width: 100%;
  display: flex;
  font-size: 12px;
  font-weight: 400;
}
.dot {
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #f8ca54;
  left: 0;
  top: 2.5px;
}
.dotContainer {
  position: relative;
  width: 12px;
  height: 100%;
  margin-right: 20px;
}
.icon {
  margin-left: 20px;
}
.subject {
}
.note {
  color: #8faac3;
}
