.questionnaire-builder {
  display: flex;
  gap: 1.5rem;
  flex-grow: 1;
  max-height: 83%;
}

.questionnaire {
  border: 3px solid var(--light-gray);
  border-radius: 5px;
}

.item {
  color: #1890ff;
  font-weight: 600;
  font-size: 12px;
  padding: 15px 20px;
  cursor: pointer;
  border-radius: 3px;
}
.item.active {
  background-color: #e7eaed;
}

.list-container {
  overflow: scroll;
  width: 50%;
}

.error-message {
  color: var(--red-high-risk);
  font-size: 12px;
  padding-bottom: 0.5rem;
}

.question {
  margin-top: 20px;
}
.question > *:first-child {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 600;
}
