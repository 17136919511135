.popupContainer {
  position: absolute;
  top: 185px;
  background-color: white;
  z-index: 1000;
  width: 600px;
  max-height: 450px;
  overflow: auto;
  border-radius: 5px;
  filter: drop-shadow(0px 5px 20px rgba(173, 182, 189, 0.6));
}

.popupHeader {
  margin: 20px;
  display: flex;
}

.popupHeaderText {
  padding-left: 20px;
  font-size: 12px;
  font-weight: 500;
}

.manageTeams {
  font-size: 13px;
  font-weight: 500;
  color: #1890ff;
}
.manageTeams:hover {
  text-decoration: underline;
  cursor: pointer;
}

.providerContainer {
  padding: 20px;
  border-bottom: 3px solid #f7f7f9;
}
