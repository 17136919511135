.photo-container {
  flex-basis: 20%;
}

.photo {
  background: var(--light-gray);
  border-radius: 50%;
  min-height: 6.25rem;
  min-width: 6.25rem;
}

.field-container {
  flex-basis: 18%;
}
