.date {
  color: #8faac3;
  font-weight: 500;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.date > span {
  margin-right: 5px;
  font-size: 11px;
}
