.datePicker {
  background-color: #fff;
  font-weight: 500;
  font-size: 11px;
}
.datePickerWrapper {
  z-index: 999;
}

.content {
  width: 100%;
  overflow: auto;
  overflow-x: hidden;
  padding-right: 8px;
  height: 28.2em;
}
.body {
  padding: 20px;
  padding-right: 8px;
  overflow: auto;
}
