.container {
  background-color: white;
  border-radius: 3px;
}

.body > * {
  margin-bottom: 10px;
}
.body > *:last-child {
  margin-bottom: 0;
}
