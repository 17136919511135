.mention {
  font-weight: 600;
}

.mention-self {
  color: var(--secundary_color);
}

.keyword {
  color: var(--bright-blue);
  font-weight: 600;
}
