.main {
  display: flex;
  align-items: center;
}

.selector {
  padding-left: 5px;
  padding-right: 5px;
}

