.horizontalLine {
  border-color: #efeff0;
  margin: 0;
  padding: 0;
}
.header {
  padding: 0 20px;
  height: 50px;
  display: flex;
  align-items: center;
  font-size: 13px;
}
.body {
  padding: 20px;
  font-size: 12px;
  flex-grow: 1;
}
