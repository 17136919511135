.rowContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  margin: 1rem 0;
}
.select {
  width: 220px;
  height: 35px;
  display: flex;
}

.daySelector {
  height: 35px;
  width: 50px;
  border: none;
  border-radius: 5px;
  background-color: white;
  text-align: center;
}