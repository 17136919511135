.main {
  padding: 13px 21px 0 21px;
  display: flex;
  border-top: 1px solid #efeff0;
  align-items: center;
}

.attatchment {
  background: none;
  border: none;
  cursor: pointer;
  margin-right: 15px;
  display: flex;
  align-items: center;
}

.messageInput {
  flex-grow: 1;
}

.messageBubbles {
  margin-right: 21px;
  display: flex;
}
