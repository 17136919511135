.main {
  padding: 19px 19.5px 10px 19.5px;
  white-space: nowrap;
}

.main > div:first-child {
  display: flex;
  margin-bottom: 15px;
}

.main> div:last-child{
  display: flex;
  justify-content: center;
  padding-top: 10px;
}

.title {
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  padding-right: 5px;
}

.disabledText {
  font-size: 12px;
  color: #8faac3
}