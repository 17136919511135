.container {
  display: flex;
  height: 100%;
  align-items: center;
  padding-right: 30px;
}

.pic {
  width: 70px;
  height: 70px;
  margin: 12px 17px;
}
.data {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 18px 0;
  font-size: 12px;
}
.name {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
}
.data span {
  margin: 0 10px;
}
.data span:first-child {
  margin-left: 0;
}
.data span:last-child {
  margin-right: 0;
}

.horizontalLine {
  margin: 0;
  border: none;
  border-top: 1px solid #efeff0;
}
