progress {
  border-radius: 7px;
  width: 102px;
  height: 10px;
}
progress::-webkit-progress-bar {
  background-color: #cfd6e2;
  border-radius: 7px;
}
progress::-webkit-progress-value {
  background-color: #e7eaed;
  border-radius: 7px 0 0 7px;
  border-right: 4px solid #393e48;
}
progress::-moz-progress-bar {
  background-color: #cfd6e2;
  border-radius: 7px 0 0 7px;
  border-right: 4px solid #393e48;
}
