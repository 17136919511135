.custom-input {
  border-radius: 50%;
  min-height: 10px;
  min-width: 10px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  background: transparent;
}

.custom-input.selected {
  background: var(--green-completed);
  border: none;
}

.container {
  padding: 10px 4px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.container:last-child {
  border-bottom: none;
}
