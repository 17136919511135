.th,
.td {
}

.th:last-child,
.td:last-child {
  text-align: end;
}

.td:first-child {
  color: #1890ff;
  cursor: pointer;
}

.th:last-child {
  padding-right: 12px !important;
}
