.activeScheduleContainer {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  background: #FFF;
}

.container {
  height: 50px;
  padding: 17px 20px;
  display: flex;
  align-items: center;
}
.title {
  font-size: 13px;
  font-weight: 500;
}

.content {
  padding: 20px;
}

.horizontalLine {
  flex-grow: 1;
  border-color: #efeff0;
  margin: 0;
}

.select {
  font-size: 12px;
  font-weight: 500;
  padding-left: 10px;
  height: 35px
}
