.usedFor {
  height: 18px;
  font-size: 11px;
  padding: 3px 11px;
  display: flex;
  align-items: center;
  border: 1px solid #cfd6e2;
  margin: 0;
  width: max-content;
}
.name {
  color: #1890ff;
  cursor: pointer;
}
