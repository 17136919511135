.controlsHeader {
  font-weight: 500;
}
.controls {
  min-height: 600px;
  background-color: var(--white);
}
.controlsBody {
  min-height: 550px;
}
