.container {
  display: flex;
  align-items: center;
  padding: 20px;
}
.data {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.horizontalLine {
  margin: 0;
  border: none;
  border-top: 1px solid #efeff0;
}
.name {
  font-size: 13px;
  font-weight: 600;
}
.timeLeft {
  font-size: 12px;
  font-weight: 400;
}
.chart {
  margin-left: 10px;
}
