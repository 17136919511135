.card {
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  background: #fff;
}
.header {
  height: 50px;
  display: flex;
  justify-content: space-between;
}
.body {
  padding: 20px 0;
}
.title {
  display: flex;
  align-items: center;
  padding: 0 20px;
  font-size: 13px;
  font-weight: 500;
}
.controls {
  display: flex;
  align-items: center;
  padding-right: 20px;
}
.controls > *:first-child {
  margin-right: 10px;
}
.horizontalLine {
  flex-grow: 1;
  border-color: #efeff0;
  margin: 0;
  padding: 0;
}
.itemContainer {
  height: 45px;
  margin: 5px 20px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.itemContainer :hover {
  background: #e7eaed;
}

.item {
  width: 100%;
  height: 40px;
  padding: 10px 10px 10px 10px;
}

.content {
  display: flex;
  width: 100%;
  align-items: center;
}
.rowContainer {
  display: flex;
  padding: 5px 20px 0 20px;
  min-height: 46px;
}
.input {
  background-color: #f8f9fb;
  width: 100%;
}