.personal-information-side {
  flex-basis: 15%;
}
.title {
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--black-text);
}

.description {
  color: var(--gray-low-risk);
}
