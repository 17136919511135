.header {
  text-transform: uppercase;
  color: var(--gray-low-risk);
  font-weight: 500;
  background: var(--light-gray);
}

.name-th {
  flex-basis: 90%;
}
