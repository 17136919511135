.main {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  line-height: 15.6px;
  font-weight: 500;
}

.filtersContainer {
  display: flex;
}

.filtersContainer > * {
  margin: 0 5px;
}

.filtersContainer > *:last-child {
  margin-right: 0px;
}

.filtersContainer > *:first-child {
  margin-left: 0px;
}
