.cardContainer {
  margin-top: 5px;
}

.cardBody {
  padding: 0px;
}

.cardContent {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.1s ease-in;
}

.title {
  color: #393e48;
  font-size: 12px;
}

.text {
  font-size: 12px;
  color: #8faac3;
}
