/* .row {
  flex-grow: 1;
} */

.element,
.header {
  margin: 0 !important;
  flex: 1 1 0;
  width: 0;
}

.dropdown-container {
  background-color: var(--light-gray);
}
