.patient-profile-popup {
  background: var(--dark-blue);
  min-width: 320px;
  top: 100%;
  right: 5%;
  color: white;
  filter: drop-shadow(0px 5px 20px rgba(183, 197, 209, 0.6));
}
.patient-profile-popup::before {
  position: absolute;
  top: -10px;
  left: 50%;
  content: '';
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;

  border-bottom: 10px solid var(--dark-blue);
}

.both-borders {
  border-block: 1px solid var(--dark-blue-border);
}

.border-bottom {
  border-bottom: 1px solid var(--dark-blue-border);
}

.image-points-container {
  padding: 2.125rem;
}

.activity-points-label {
  font-size: 2rem;
}

.disabled {
  color: grey;
}
.disabled:hover {
  text-decoration: none;
  cursor: not-allowed;
}
