.container {
  display: flex;
  flex-direction: column;
  padding: 20px 17px 15px 20px;
  justify-content: space-between;
}
.title {
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
}
.careTeam > * > *:first-child {
  font-weight: 600;
}
