.date {
  font-weight: 500;
  font-size: 13px;
  line-height: 15.5px;
}
.title {
  font-size: 13px;
  text-transform: uppercase;
}
.description {
  color: #8faac3;
}
.circle {
  height: 10px;
  width: 10px;
  border: 1px solid #f5485c;
  border-radius: 80%;
  cursor: pointer;
}
