.custom-input {
  border-radius: 50%;
  min-height: 10px;
  min-width: 10px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  background: transparent;
}

.custom-input.selected {
  background: var(--green-completed);
  border: none;
}

.container {
  padding: 10px 4px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.container:last-child {
  border-bottom: none;
}
.question {
  margin-top: 20px;
}
.question > *:first-child {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 600;
}

.error-message {
  color: var(--red-high-risk);
  font-size: 12px;
}

.list {
  overflow: auto;
  max-height: 300px;
}
.not-found-message {
  color: #8faac3;
  font-size: 12px;
}
