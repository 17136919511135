.actions {
  display: flex;
  justify-content: space-between;
  width: 30px;
}
.tagItem {
  display: inline-block;
  cursor: pointer;
}
.search {
  margin-right: 10px;
}
.backgroundBlue{
   color: #1890FF;
}
