.input {
  max-height: 35px;
  max-width: 300px;
}
.container label,
.form-label {
  font-size: 13px;
  font-weight: 500;
  line-height: 15.6px;
  flex-basis: 100px;
}
.with-separator {
  border-bottom: 3px solid var(--gray-200);
}
.container {
  padding: 10px 20px;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.date-picker {
  background-color: var(--light-gray);
  display: flex;
  width: 100%;
  max-height: 35px;
  max-width: 300px;
  justify-content: space-between;
}
.date-picker > label {
  flex-basis: 0;
  margin-bottom: 0;
  display: flex;
  align-items: center;
}

.date-picker > input {
  background-color: var(--light-gray);
  font-weight: 400;
  font-size: 12px;
  padding: 10px;
}

.container textarea {
  padding: 15px;
  background-color: white;
  border: 1px solid var(--gray-tags);
  box-shadow: inset 1px 1px 1px 3px rgba(55, 70, 84, 0.05);
}
