.collapseButton {
  margin-left: 5px;
  display: flex;
  align-items: center;
}
.collapseButton > span {
  color: #cfd6e2;
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
}
.arrow {
  margin-top: 3px;
  margin-left: 15px;
}
.horizontalLine {
  flex-grow: 1;
  border-color: #e7eaed;
  margin: 0;
  padding: 0;
  margin-left: 13px;
}
