.vytracSelect {
  display: block;
  width: 100%;
  padding-left: 10px;
  -moz-padding-start: calc(0.75rem - 3px);
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #f8f9fb;
  background-image: url('/src/assets/icons/arrow_down.png');
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 7px 4.6px;
  border: 0px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.selectContainer {
  display: flex;
  width: 100%;
  height: 100%;
}
