.title {
  font-weight: 500;
  font-size: 13px;
}
.counter {
  font-weight: 500;
  font-size: 11px;
  width: 24px;
  height: 24px;
  color: white;
  background-color: #373372;
  border-radius: 100%;
  text-align: center;
  line-height: 24px;
}
.card {
  max-height: 878px;
  background-color: white;
  width: 100%;
  margin-bottom: 10px;
}
