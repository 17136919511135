.main {
  background-color: white;
  padding: 17px 14px 17px 21px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #efeff0;
}

.main > span {
  font-weight: 400;
  font-size: 13px;
  line-height: 15.6px;
}
