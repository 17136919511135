.container {
  display: flex;
  flex-direction: column;
  padding: 20px 30px 12px 20px;
  justify-content: space-between;
}
.title {
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
}
.select {
  font-size: 12px;
  width: 100px;
  height: 35px;
  padding-left: 12px;
}
