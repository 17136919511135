.main {
  display: flex;
  align-items: center;
}

.main button,
.main span {
  font-size: 12px;
  font-weight: 500;
  line-height: 14.4px;
  margin: 0 9px;
}

.main span {
  color: #393e48;
}

.main button {
  background: none;
  border: none;
  text-transform: uppercase;
  color: #1890ff;
}

.main button:first-child {
  margin-left: 0;
}

.main button:last-child {
  margin-right: 0;
}

.main button:hover {
  text-decoration: underline;
  cursor: pointer;
}

.main button:disabled {
  opacity: 0.3;
}

.main button:disabled:hover {
  text-decoration: none;
  cursor: default;
}