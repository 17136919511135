.section {
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.title {
  font-size: 12px;
  font-weight: 500;
}
.value {
  font-size: 12px;
  font-weight: 500;
}
