.chats-container {
  margin: 10px auto;
  overflow: auto;
  padding-right: 10px;
  margin-right: 10px;
  max-height: 615px;
}
.chats-container > * {
  margin-bottom: 3px;
}
.chats-container > *:last-child {
  margin-bottom: 0px;
}
