.searchbox {
  width: 100%;
  display: flex;
  align-items: center;
  height: 100%;
}
.searchbox > input {
  width: 100%;
  border: 0;
  color: #8faac3;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  height: 100%;
}
.searchbox::before {
  background-image: url('assets/icons/magnifier.png');
  background-size: 15px 15px;
  display: inline-block;
  width: 15px;
  height: 15px;
  content: '';
  margin-right: 15px;
}
.menu {
  border-radius: 0 0 5px 5px !important;
  inset: 0 17px auto -43px !important;
  width: auto !important;
  box-shadow: 0px 2px 20px rgba(133, 142, 151, 0.2) !important;
  border-color: transparent !important;
}
.menu > * {
  border-top: 3px solid #f7f7f9 !important;
}
.menu > *:first-child {
  border-top: 0 !important;
}
