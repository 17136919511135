.rowContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 20px;
  margin: 1rem 0;
}
.select, .input {
  width: 220px;
  height: 35px;
  display: flex;
}

.input {
  background-color: #F6F7FA;
  border-radius: 5px;
  border: none;
  text-align: end;
  padding-right: 40px;
}
.centerText {
  display: flex;
  align-items: center;
  justify-content: center;
}
