.container {
    width: 16px;
    height: 16px;
    background-color: #8FAAC3;
    border-radius: 100px;
    position: relative;
    cursor: pointer;
}

.sign {
    color: white;
    position: absolute;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    bottom: 1px;
    user-select: none;
}