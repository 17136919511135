.react-flow__edge.selected .react-flow__edge-path {
  stroke: #8faac3 !important;
  stroke-width: 3px !important;
}

.react-flow__handle {
  width: 12px !important;
  height: 12px !important;
  background-color: #fff !important;
  border: 3px solid #cfd6e2 !important;
}
