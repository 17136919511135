.popup {
  position: absolute;
  min-width: 400px;
  background-color: white;
  z-index: 99;
  border-radius: 0 0 5px 5px;
  padding: 20px 0;
  filter: drop-shadow(0px 5px 20px rgba(183, 197, 209, 0.6));
}
.actions {
  background: linear-gradient(180deg, #eaf1f7, #f8f9fb);
}
.last-action {
  background-color: #f8f9fb;
}
