.container {
  margin: 20px;
  background-color: white;
}
.cardBody {
  padding: 0;
}
.tabs-container {
  background-color: #e7eaed;
}
