.header {
  font-size: 13px;
  font-weight: 500;
  margin: 17px 20px;
}
.horizontalLine {
  flex-grow: 1;
  border-color: #efeff0;
}
.content {
  padding: 20px;
  display: flex;
  flex-direction: column;
}
.title {
  font-size: 12px;
  font-weight: 500;
  width: 100px;
}
.value {
  font-size: 12px;
  font-weight: 400;
}
.titleRow {
  display: flex;
  padding-top: 10px;
}

.section {
  padding: 0 20px;
}
