abbr.Calendar__weekDay {
  cursor: auto;
  text-decoration: none;
  color: black;
  font-size: 12px;
  font-weight: 500;
}
.Calendar__day:focus {
  outline: none;
}
