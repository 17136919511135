.body {
  display: flex;
}

.body > div:last-child {
  flex-grow: 1;
}

.photoContainer {
  display: flex;
  align-items: center;
}

.photo {
  width: 100px;
  height: 100px;
  margin-left: 43px;
  margin-right: 61px;
  border-radius: 64px;
}

.info-container {
  display: flex;
  flex-wrap: wrap;
  padding-top: 19px;
  padding-bottom: 8px;
}

.info-container > div {
  margin-right: 23px;
  min-width: 110px;
}

.title {
  font-weight: 500;
  margin-bottom: 20px;
}

.value {
  margin-bottom: 31px;
}
