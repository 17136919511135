.container {
  font-size: 12px;
  font-weight: 400;
}

.icon {
  margin-right: 10px;
}

.title {
  text-transform: uppercase;
  font-weight: 600;
}

.range {
  color: var(--gray-low-risk);
  font-weight: 500;
}

.headers {
  color: var(--gray-low-risk);
  font-weight: 500;
}

.row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 0.25rem 1rem;
  gap: 1rem;
  white-space: nowrap;
  align-items: center;
}

.row-header {
  background-color: #f9f9fa;
}

.row-controls {
  padding: 0.5rem 1rem;
}

.threshold-error-message {
  color: red;
  font-size: 12px;
  font-weight: 400;
  white-space: nowrap;
  padding: 0.5rem 1rem;
}
