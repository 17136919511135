.container {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 17px 20px;
}

.horizontalLine {
  border-color: #efeff0;
  flex-grow: 1;
  margin: 0;
}

.inputTitle {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 10px;
}
.inputTitleMargin {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 10px;
  width: 20%;
}
.inpoutContainer {
  font-size: 12px;
  font-weight: 500;
}
.input {
  border: 1px solid #e7eaed;
  width: 100%;
  height: 25px;
  border-radius: 3px;
  background: #f8f9fb;
  padding: 10px;
}
.inputDefaultProvider {
  border: 1px solid #e7eaed;
  width: 100%;
  height: 50px;
  border-radius: 3px;
  background: #f8f9fb;
  padding: 10px;
}
.sectionTitle {
  font-size: 16px;
  width: 140px;
  margin: 10px 2rem 10px 10px;
}
.description {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #8faac4;
}
.signatureContainer {
  border: 1px solid #e7eaed;
  width: 100%;
  height: 50px;
  border-radius: 3px;
  background: #f8f9fb;
  padding: 10px;
}
.signature {
  position: relative;
  display: revert;
  width: 18%;
  height: 50%;
  left: 78%;
  top: 10px;
  opacity: 0;
  cursor: pointer;
  z-index: 1;
}
.changeSpan {
  position: relative;
  left: 60%;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  color: #1890ff;
}
.signatureImg  {
  position: relative;
  right: 25%;
  max-width: 100px;
  max-height: 35px;
}
