.today-button {
  background: inherit;
  color: var(--bright-blue);
  font-weight: 500;
  border: none;
  padding: 0;
  margin: 0;
}
.today-button:hover {
  text-decoration: underline;
  cursor: pointer;
}

.messages-date-filter-input {
  width: 50%;
}
.messages-date-filter-input:hover {
  cursor: pointer;
}
