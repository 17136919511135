.period {
  margin: 0;
  color: #1890ff;
  cursor: pointer;
  font-size: 12px;
  user-select: none;
}

.selected {
  color: #393e48;
  cursor: auto;
}
