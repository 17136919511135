.button {
  height: 25px;
  padding: 0 10px;
  margin-right: 1rem;
}
.codeName {
  color: #1890ff;
}
.row {
  background-color: white;
  border-bottom: 3px solid #f7f7f9;
  height: 55px;
}
.search {
  margin-right: 10px;
}
.actions {
  display: flex;
  justify-content: space-between;
  width: 30px;
}
