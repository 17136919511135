.popupHeader {
  margin: 20px;
  display: flex;
}

.popupHeaderText {
  padding-left: 20px;
  font-size: 12px;
  font-weight: 500;
}

.manageTeams {
  font-size: 13px;
  font-weight: 500;
  color: #1890ff;
}
.manageTeams:hover {
  text-decoration: underline;
  cursor: pointer;
}

.providerContainer {
  padding: 20px;
  border-bottom: 3px solid #f7f7f9;
}
