.card {
  background-color: #ffffff;
  padding: 0;
  font-size: 12px;
  font-weight: 400;
  border-radius: 5px;
}
.head {
  padding: 10px 20px;
}
.body {
  padding: 20px;
}
.footer {
  background-color: #f8f9fb;
  padding: 7px 12px;
}
.horizontalLine {
  border-color: #efeff0;
  margin: 0;
  padding: 0;
}
.container {
  margin: 20px;
}
