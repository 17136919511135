.main {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.container {
  display: flex;
  flex-grow: 1;
  align-items: center;
  white-space: nowrap;
  text-transform: capitalize;
  font-weight: 500;
}
.container > * {
  margin-right: 10px;
  display: flex;
  align-items: center;
}
.container > *:last-child {
  margin-right: 0;
}
.select {
  width: 70px;
  font-size: 11px;
}
