.circle {
  background: var(--light-gray);
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.care-team-tiers-container {
  background: var(--gray-200);
}

.primary-physician-container {
  background: var(--yellow-300);
}

.item-container {
  padding: 0.5rem;
  background: var(--white);
  min-height: 55px;
}

.table-header {
  background: var(--light-gray);
  color: var(--gray-low-risk);
  text-transform: uppercase;
  font-weight: 600;
  font-size: 11px;
}

.flex-basis-priority {
  flex-basis: 10%;
}
.flex-basis-drag-drop {
  flex-basis: 5%;
}
.arrow-container {
  width: 15px;
  height: 15px;
  background: var(--gray-tags);
}

.collapsable-header {
  padding: 0.5rem;
  background: var(--white);
  min-height: 55px;
}

.disabled-option-label {
  color: var(--gray-low-risk);
  opacity: 0.8;
}
