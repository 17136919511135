.button {
  padding: 5px 20px;
  margin-right: 10px;
}
.backContainer {
  max-width: 160px;
  margin: 20px;
  display: flex;
  align-items: center;
}
.rpmTemplateActions {
  display: flex;
  font-size: 11px;
  height: 25px;
  position: absolute;
  right: 20px;
  top: 62px;
}
.rpmTemplateActions button {
  padding: 0 10px;
}
.rpmTemplateActions > * {
  margin-right: 10px;
}
.rpmTemplateActions > *:last-child {
  margin-right: 0;
}
