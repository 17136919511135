.container {
  position: relative;
  padding: 12px 15px;
  padding-right: 0;
  border-top: 1px solid #efeff0;
}
.actions {
  border: none;
  padding-left: 0;
  padding-right: 0;
}
