.message {
  flex-direction: row-reverse !important;
  justify-content: start;
  background-color: #f8f9fb;
  border-radius: 5px;
  padding: 7px;
  align-items: center;
}
.time {
  color: #8faac3;
  margin-right: 10px;
}
.data {
  background-color: #e7eaed;
  padding: 0 10px;
  border-radius: 20px;
  font-size: 11px;
  font-weight: 400;
  line-height: 17px;
}
.bubble {
  display: flex;
  align-items: center;
}
.bubble > *:first-child {
  margin-left: 0 !important;
}
.status {
  font-size: 12px;
  font-weight: 400;
  padding-top: 20px;
}
