.calendar {
  font-size: 0.6vw;
  width: 100%;
}

.calendarTitle {
  padding: 0 20px;
  font-size: 0.8vw;
  font-weight: 500;
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  background: #fff;
}

.calendarContainer {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}
