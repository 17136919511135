.content {
  padding: 20px;
  display: flex;
}


.content > *:first-child {
  margin-right: 20px;
  width: 75%;
}
.content > *:last-child {
  width: 25%;
}

@media (max-width: 1500px){
  .content {
    flex-direction: column;
  }

  .content > * {
    min-width: 500px;
  }

  .content>*:first-child {
    width: 100%;
    margin-bottom: 20px;
  }
  
  .content>*:last-child {
    width: 100%;
  }
}


.card {
  height: fit-content;
  background-color: white;
  border-radius: 5px;
  white-space: nowrap;
}
