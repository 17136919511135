.weekDay, .weekDaySelected {
  cursor: pointer;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  -moz-user-select: none;
}
.weekDaySelected {
  background-color: #393e48;
  color: #fff;
  border-radius: 100%;
}
