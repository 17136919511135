.datePicker {
  background-color: #fff;
  font-weight: 500;
  font-size: 11px;
  left: -1.75rem;
  position: relative;
}
.datePickerWrapper {
  left: 10rem;
  position: absolute;
  z-index: 999;
}
.calendar {
  z-index: 999;
}

.content {
  width: 100%;
  overflow: auto;
  overflow-x: hidden;
  /* padding: 20px 8px 20px 20px; */
  padding-right: 8px;
  height: 28.2em;
}
.body {
  padding: 20px;
  padding-right: 8px;
  overflow: auto;
}
