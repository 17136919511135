.careTeamContainer {
  display: flex;
}
.careTeamContainer > * {
  margin: 0 0 0 -7px;
}
.careTeamContainer > *:first-child {
  margin: 0;
}
.patient {
  display: flex;
  align-items: center;
  font-size: 12px;
  gap: 5px;
  width: 350px
}
.patient > *:first-child {
  margin-right: 30px;
}

.text {
  font-size: 12px;
  display: flex;
  align-items: center;
  flex-basis: 70px;
}
.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.container > *:first-child {
  width: 50%;
  display: flex;
  justify-content: space-between;
}
