.main {
  display: flex;
  align-items: center;
  padding: 14px 18px;
  white-space: nowrap;
}

.title {
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  padding-right: 10px;
  color: #8faac3;
}

.historyButton {
  color: var(--bright-blue);
  font-weight: 600;
  font-size: 12px;
  white-space: nowrap;
  background: inherit;
  border: none;
}
.historyButton:hover {
  text-decoration: underline;
  cursor: pointer;
}
.historyButton:focus {
  outline: none;
}
