.table {
  display: grid;
}

.headers-container {
  display: contents;
}
.headers-container > *:first-child {
  padding-left: 20px;
}
.header {
  background-color: #f8f9fb;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 500;
  color: #8faac3;
  min-height: 40px;
  line-height: 40px;
}
.elements-container {
  display: contents;
}
.elements-container > *:first-child {
  padding-left: 20px;
}
.element {
  display: flex;
  align-items: center;
  padding: 20px 0;
}

.noSelect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.arrowContainer {
  cursor: pointer;
  margin-right: 3%;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.arrowContainerOpened {
  cursor: pointer;
  margin-right: 3%;
  transform: rotate(90deg);
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
