.button-control {
  text-transform: uppercase;
  padding: 5px 15px;
  font-size: 11px;
  font-weight: 700;
  background: #e7eaed;
  border: none;
  border-radius: 5px;
  line-height: 20px;
  cursor: pointer;
}

a.button-control,
a.button-control:visited {
  text-decoration: none;
  color: var(--black-text);
  display: inline-block;
}

a.button-control.selected:visited,
a.button-control.selected {
  color: white;
}

.button-control:hover,
.selected,
a.button-control:hover {
  background-color: #282556 !important;
  color: white;
  text-decoration: none;
}
