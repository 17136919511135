.providerPopupContainer {
  position: absolute;
  top: 185px;
  background-color: white;
  z-index: 1001;
  width: 400px;
  /* max-height: 400px; */
  overflow: auto;
  border-radius: 5px;
  filter: drop-shadow(0px 5px 20px rgba(173, 182, 189, 0.6));
}

.bubbleContainer {
  width: 80px;
  height: 80px;
  margin: 20px 0px 0px 0px;
}

.tagListContainer {
  flex-wrap: wrap;
}

.nameContainer {
  font-weight: 500;
  font-size: 13px;
  color: #1890ff;
  padding-top: 20px;
}

.condition {
  font-weight: 400;
  font-size: 12px;
  color: #393e48;
  padding-bottom: 20px;
}

.label {
  font-size: 12px;
  font-weight: 500;
}

.textContainer {
  font-size: 12px;
  font-weight: 400;
  padding: 10px;
  border: 1px solid #efeff0;
  border-radius: 3px;
  color: #393e48;
  justify-content: space-between;
}

.providerPopupBody > div {
  padding-bottom: 3px;
}
