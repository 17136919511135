.table {
  width: 100%;
  white-space: nowrap;
}

.table td,
.table th {
  max-width: 30%;
}
.table td:first-child {
  color: var(--gray-low-risk);
}

.table td {
  padding-block: 0.5rem;
}

.table th {
  font-weight: 400;
}

.table th:first-child,
.table td:first-child {
  width: 16.7%;
}

.error-reserved {
  min-height: 1rem;
}

.remove-address {
  flex-basis: 50%;
}

.body {
  padding: 20px;
  font-size: 12px;
}

.max-width-select {
  max-width: 250px;
}
