.patient-generic-table thead tr {
  background-color: #f8f9fb;
  border-left: 1px solid #efeff0;
  border-right: 1px solid #efeff0;
  text-transform: uppercase;
  color: #8faac3;
}

.patient-generic-table {
  margin: 0;
}

.patient-generic-table td,
.patient-generic-table th {
  vertical-align: middle !important;
  border-bottom: 3px solid #f8f9fb;
}

.patient-generic-table tr td:first-child,
.patient-generic-table tr td:last-child,
.patient-generic-table tr th:first-child,
.patient-generic-table tr th:last-child {
  padding: 13px 20px;
}
