.hourContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem 3.2rem 0 2rem;
  margin: 0 0 1rem 0;
  font-size: 13px;
  box-shadow: 0px -1px 0px #e7eaed;
  height: 100%;
}
.numberInput {
  width: 35px;
  height: 35px;
  border: none;
  font-size: 13px;
}

.textCenter {
  white-space: nowrap;
  text-align: center;
  line-height: 2.3rem;
  margin-right: 20px;
}

.selector {
  text-align: center;
  line-height: 2.3rem;
  width: 40px;
  height: 35px;
  cursor: pointer;
  transition: background 0.5s ease, color 0.5s ease;
}
.selectedSelector {
  background: #393e48;
  color: #fff;
  border-radius: 3px;
  text-align: center;
  line-height: 2.3rem;
  width: 40px;
  height: 35px;
  cursor: pointer;
  transition: background 0.5s ease, color 0.5s ease;
}
