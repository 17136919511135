.breadcrumb-item + .breadcrumb-item::before {
  background-image: url('/src/assets/icons/arrow_left_dark.png') !important;
  transform: rotate(180deg);
  background-size: 5px 8px;
  background-repeat: no-repeat;
  width: 5px;
  height: 8px;
  content: '' !important;
  margin: 0 20px !important;
  padding: 0 !important;
}
.breadcrumb {
  background: none;
  margin: 0 !important;
  font-size: 13px;
  text-transform: capitalize;
}
