.main {
  padding: 19px 19.5px 0px 19.5px;
  display: flex;
  border-right: none !important;
  white-space: nowrap;
  vertical-align: middle;
}

.selectContainer {
  margin-right: 14px;
}

.title {
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 17px;
}

.activitySelect {
  font-size: 12px;
  height: 25px;
  margin-top: 10px;
  background-color: white;
}