.container {
  background: #fff;
  border-radius: 0px 0px 5px 5px;
}
.resourceImg {
  max-width: 100%;
  min-width: 100%;
}
.itemTitle {
  margin: 10px 20px 10px 15px;
  font-size: 12px;
  font-weight: 600;
}
.itemText {
  margin: 10px 10px 10px 15px;
  font-size: 10px;
}
