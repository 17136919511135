.container {
  padding: 3px;
  background-color: #e7eaed;
  border-radius: 5px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 11px;
  overflow-x: auto;
  position: relative;
}
.goalSelected {
  border-radius: 5px;
  background-color: white;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.15);
  color: #393e48 !important;
  font-weight: 700;
}
.goal {
  padding: 5px 30px;
  color: #1890ff;
  height: 23px;
  display: flex;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}
