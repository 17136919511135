.main tbody {
  background-color: white;
}

.main thead {
  background-color: #f8f9fb;
  color: #8faac3;
  font-size: 11px;
  font-weight: 500;
  line-height: 13.2px;
  text-transform: uppercase;
}

.main tr {
  border-bottom: 3px solid #eeeeef;
}

@media (max-width: 1400px) {
  .main th {
    vertical-align: middle !important;
  }
}
