.top-container {
  max-height: 50vh;
  width: 100%;
  z-index: -1;
}

.login-background-image-gradient {
  background-image: linear-gradient(to bottom right, #96d7cc, #5676a5);
  z-index: -6;
}

.bottom-container {
  flex-grow: 1;
  width: 100%;
  height: 100%;
  background-color: var(--gray-300);
  z-index: -1;
}

.register-bg {
  height: 100%;
  width: 100vw;
  object-fit: cover;
  object-position: 0 0;
}
.vytrac-logo-container {
}

.vytrac-logo {
  height: 76px;
  width: 200px;
}

.main {
  height: 100vh;
  width: 100vw;
}
