.bubble-group-container{
  margin-top: 3px;
}

.bubble-group-container > * {
  margin: 0 0 0 -7px;
}

.bubble-container {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 0.35rem;
  font-weight: 600;
  background: var(--gray-tags);
}

.bubble-container > * {
  flex-basis: 50%;
}

.bubble-container > div:nth-child(odd) {
  border-right: 1px solid white;
}

.bubble-container > div:nth-child(even) {
  border-left: 1px solid white;
}

.bubble-container > .four-elements-container:nth-child(1) > div {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0 0.1rem 0.1rem 0;
}
.bubble-container > .four-elements-container:nth-child(2) > div {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0 0 0.1rem 0.1rem;
}
.bubble-container > .four-elements-container:nth-child(3) > div {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.1rem 0.1rem 0 0;
}
.bubble-container > .four-elements-container:nth-child(4) > div {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0.1rem 0 0 0.1rem;
}

.bubble-container > div:nth-child(1),
.bubble-container > div:nth-child(2) {
  border-bottom: 1px solid white;
}

.bubble-container > div:nth-child(3),
.bubble-container > div:nth-child(4) {
  border-top: 1px solid white;
}

/* only three elements */
.bubble-container > div:nth-child(3):nth-last-child(1) {
  border: none;
}
.bubble-container > div:nth-child(3):nth-last-child(1) > div {
  padding-right: 0.1rem;
  right: 50%;
  transform: translateX(50%);
}

/* only two elements */
.bubble-container > div:nth-child(1):nth-last-child(2),
.bubble-container > div:nth-child(2):nth-last-child(1) {
  border-bottom: none;
}
.bubble-container > div:nth-child(1):nth-last-child(2) > div,
.bubble-container > div:nth-child(2):nth-last-child(1) > div {
  padding-right: 0.1rem;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
}
