.insurance-information-main-container {
  width: 750px;
}

.insurance-information-main-container td {
  padding-top: 15px;
  padding-bottom: 15px;
}

.insurance-information-main-container table {
  margin: 0;
}

.insurance-information-main-container tr:last-child td {
  padding-bottom: 15px;
}
