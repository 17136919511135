.headers {
  display: flex;
  justify-content: space-between;
  padding: 0 10px 0 10px;
  background-color: #f8f9fb;
  text-transform: uppercase;
  color: #8faac3;
  font-size: 11px;
  margin-bottom: 20px;
}
.row {
  display: flex;
  justify-content: space-between;
  padding: 0 10px 0 10px;
  font-size: 11px;
  text-transform: uppercase;
  align-items: center;
}
.subRow {
  display: flex;
  justify-content: space-between;
  padding: 0 10px 0 10px;
  font-size: 11px;
  text-transform: capitalize;
  align-items: center;
  color: #8faac3;
  padding-left: 20px;
}
.container {
  font-size: 11px;
}
