.buttonContainer {
  padding-bottom: 10px;
}
.surveyName {
  cursor: pointer;
  color: #1890ff;
  font-size: 12px;
}

.surveyTemplateActions {
  display: flex;
  font-size: 11px;
  height: 25px;
}
.surveyTemplateActions button {
  padding: 0 10px;
  height: 25px;
}

.th,
.td {
}

.td {
  text-align: end;
}
