.main {
  border: 1px solid #e7eaed;
  border-radius: 3px;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  cursor: text;
}

.main input {
  border: none;
  font-size: 12px;
  flex-grow: 1;
}

.main img {
  margin-right: 8px;
}

.menu {
  border-radius: 0 0 5px 5px !important;
  inset: 0 17px auto -43px !important;
  width: auto !important;
  box-shadow: 0px 2px 20px rgba(133, 142, 151, 0.2) !important;
  border-color: transparent !important;
}
.menu > * {
  border-top: 3px solid #f7f7f9 !important;
}
.menu > *:first-child {
  border-top: 0 !important;
}
