.card {
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  background: #fff;
  padding-bottom: 10px;
}
.header {
  height: 50px;
  display: flex;
  justify-content: space-between;
}
.title {
  display: flex;
  align-items: center;
  padding: 0 20px;
  font-size: 13px;
  font-weight: 500;
}
.controls {
  display: flex;
  align-items: center;
  padding-right: 20px;
}
.controls > *:first-child {
  margin-right: 10px;
}
.expandArrow{
  background-image: url('/src/assets/icons/expand.png') !important;
  width: 11px;
  height: 11px;
  cursor: pointer;
  margin: 1rem;
}
.horizontalLine {
  flex-grow: 1;
  border-color: #efeff0;
  margin: 0;
  padding: 0;
}
.body {
  max-height: 75px;
  margin: 1rem 1rem 1rem 1rem;
}
.bodyPatient {
  max-height: 80px;
  margin: 1rem 1rem 1rem 1rem;
}
.bodyDevices {
  max-height: 200px;
  margin: 1rem 1rem 1rem 1rem;
}
.datePicker{
  z-index: 0;
}

.filterbutton {
  background: #e7eaed;
  border: 1px solid #cfd6e2;
  box-sizing: border-box;
  border-radius: 80px;
  font-size: 11px;
  padding: 2px 15px 2px 15px;
  margin-right: 5px;
  overflow: hidden;
  white-space: nowrap;
  height: 25px;
}
