.row {
  padding: 7px 10px 7px 10px;
  margin: 5px 20px;
  display: flex;
  max-height: 40px;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.4px;
  cursor: pointer;
  border-radius: 3px;
}
.row > *:first-child > *:first-child {
  flex-basis: 35px;
}

.dot {
  border-radius: 100%;
  background-color: #e7eaed;
  width: 16px;
  height: 16px;
  position: relative;
}
.tag {
  height: 25px;
  display: flex;
  align-items: center;
  margin: 0;
  width: fit-content;
  overflow: unset !important;
}
