.header {
  font-size: 13px;
  font-weight: 500;
  margin: 17px 20px;
  display: flex;
  justify-content: space-between;
}
.content {
  padding: 20px;
  display: flex;
  flex-direction: column;
}
.horizontalLine {
  flex-grow: 1;
  border-color: #efeff0;
}
.file {
  display: flex;
  justify-content: space-between;
  height: 45px;
}
.fileImage {
  margin-right: 10px;
  display: flex;
  align-items: center;
}
.fileName {
  font-size: 12px;
  font-weight: 400;
  display: flex;
  align-items: center;
}
.imageContainer {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.defaultImage {
  width: 12px;
  height: 15px;
}
.image {
  width: 100%;
  height: 100%;
  border-radius: 3px;
}
