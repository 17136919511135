.main {
  padding: 15.5px 21px;
  border-radius: 3px;
  margin: 1.5px 0;
  display: flex;
  justify-content: space-between;
}

.check-icon {
  margin-right: 10px;
}

.green-color {
  color: #86b463;
}

.btn {
  margin-left: 10px;
  cursor: pointer;
}
