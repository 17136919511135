input[type='checkbox'].vytrac-checkbox {
  display: none;
}
input[type='checkbox'].vytrac-checkbox {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 8px;
  width: 8px;
  margin: -1px;
  padding: 0;
  border: 0;
}
input[type='checkbox' i] {
  background-color: initial;
  cursor: default;
  appearance: auto;
  box-sizing: border-box;
  margin: 3px 3px 3px 4px;
  padding: initial;
  border: initial;
}
input[type='checkbox'].vytrac-checkbox:checked + label.vytrac-checkbox-label {
  background-position: 0 -13px;
}
input[type='checkbox'].vytrac-checkbox + label.vytrac-checkbox-label {
  padding-left: 17px;
  height: 13px;
  display: inline-block;
  line-height: 13px;
  background-repeat: no-repeat;
  background-position: 0 0;
  font-size: 13px;
  vertical-align: middle;
  cursor: pointer;
}
label.vytrac-checkbox-label {
  background-image: url('/src/assets/icons/checkbox.png');
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
