.empty-notes {
  color: #cfd6e2;
  font-size: 13px;
  font-weight: 500;
}
.add-note {
  text-align: center;
  color: var(--bright-blue);
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
}
