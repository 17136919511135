.input,
.label {
  font-size: 12px !important;
  line-height: 14.4px;
  border: none !important;
  margin-bottom: 10px;
}

.labelContainer {
  margin-bottom: 6px;
}

.label {
  font-weight: 500;
}

.input {
  font-weight: 400;
  font-size: 12px;
}
