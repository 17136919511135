.careTeamContainer {
  display: flex;
}
.careTeamContainer > * {
  margin: 0 0 0 -7px;
}
.add {
  font-size: 16px;
  display: flex;
  align-items: center;
}
