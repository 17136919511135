.input {
    padding-left: 10px;
    margin-left: 5px;
    height: 35px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.timeSelectorContainer {
    position: absolute;
    right: 15%;
    background-color: white;
    padding: 5px;
    border-radius: 5px;
    box-shadow: 0px 5px 20px rgba(183, 197, 209, 0.6);
    width: 310px
}

.trashButton {
    outline: none;
    background-color: inherit;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 80px;
    transition: background-color 0.2s;
    width: 30px;
    height: 30px;
}

.trashButton:hover{
    background-color: lightgray;
}

.trashButton:disabled{
    opacity: 0;
}

.addButton {
    background-color: inherit;
    padding: 3px;
    margin-left: 17px;
    border-radius: 3px;
    font-size: 13px;
    color: #3378FF;
    outline: none;
    border: none;
    font-weight: 500;
}

.addButton:hover {
    text-decoration: underline;
}
