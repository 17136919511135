.questionnaire-builder {
  display: flex;
  gap: 1.5rem;
  flex-grow: 1;
}

.nameInput {
  margin-bottom: 20px;
  background-color: #F8F9FB;
  font-size: 13px;
  width: 100%;
  border-radius: 3px;
  height: 35px;
  padding-left: 10px;
}

.errorLabel {
  color: var(--red-high-risk);
  font-size: 12px;
}

.questionnaire {
  border: 3px solid var(--light-gray);
  border-radius: 5px;
  min-height: 100%;
}

.item {
  color: #1890ff;
  font-weight: 600;
  font-size: 12px;
  padding: 15px 20px;
  cursor: pointer;
  border-radius: 3px;
}

.item.active {
  background-color: #e7eaed;
}

.list-container {
  overflow: scroll;
  width: 100%;
}





.question {
  margin-top: 20px;
}
.question > *:first-child {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 600;
}
