.main {
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;
    font-size: 13px;
    font-weight: 500;
    margin: 15px;
}

.fieldInput, .pickerContainer {
    background-color: #F8F9FB;
    border-radius: 3px;
    padding-left: 10px;
    width: 100%
}

.fieldLabel {
    display: flex;
    align-items: center;
    margin: 0;
    height: 35px;
    min-width: 80px;
}

.durationSelect {
    height: 35px;
    margin-left: 5px;
    width: 80px;
    text-transform: capitalize;
}

.fieldContainer {
    margin-bottom: 10px;
}

.pickerContainer {
    height: 35px;
    display: flex;
    align-items: center;
}

.pickerInput {
    background: inherit;
}

.templateData > * {
    margin-bottom: 10px;
}

.templateData > *:last-child {
    margin-bottom: 0;
}