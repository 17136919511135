.main {
  position: relative;
}

.backgroundContainer, .playContainer {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.backgroundContainer {
  z-index: 1;
}
.playContainer{
  z-index: 100;
}

.trackButton {
  height: 20px;
  width: 20px;
}

.counter {
  position: absolute;
  width: 100%;
  text-align: center;
}
