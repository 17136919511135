.font-size-small {
  font-size: 11px;
}

.font-size-medium {
  font-size: 12px;
}

.font-size-big {
  font-size: 13px;
}

.font-size-large {
  font-size: 17px;
}

.font-size-extra-large {
  font-size: 30px;
}

.bold-blue {
  font-weight: 700;
  color: var(--primary_color);
}
.normal-text {
  font-size: 10pt;
}
.smaller-text {
  font-size: 8pt;
}
.grey-text {
  color: #8faac4;
  font-style: normal;
  font-weight: 500;
}

.text-gray-low-risk {
  color: var(--gray-low-risk);
}

.font-weight-md {
  font-weight: 500;
}
.font-weight-lg {
  font-weight: 600;
}

/* Bold is 700 so beware when using this class */
.bold-text {
  font-weight: bold !important;
}
.bolder-text {
  font-weight: bolder !important;
}

.text-gray-low-risk {
  color: var(--gray-low-risk);
}
