.activity-column {
  flex-basis: 100%;
  min-width: 0;
  border: 1px solid var(--gray-border);
  border-bottom: none;
  flex-direction: column;
  flex-grow: 1;
}

.activity-container {
  background: var(--light-gray);
  max-width: 100%;
  transition: all 0.2s ease-in-out;
}

.activity-container:hover {
  transform: scale(1.025);
  cursor: pointer;
}

.action-container {
  border-left: 5px solid #c8deb3;
}

.is-not-care-plan {
  border-color: #ac6cd3;
}

.is-not-active {
  opacity: 0.4;
}

.hour-label {
  text-transform: uppercase;
  font-weight: 500;
  margin: none;
}

.main-label {
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
  font-weight: 400;
  text-overflow: ellipsis;
}

.main-label,
.secondary-label {
  margin: 0;
}

.day-item-label {
  white-space: initial;
}

.range-activity-container-bg {
  background: var(--light-blue-01);
}

.adherence-block-container,
.range-activity-container {
  height: 1.875rem;
  background: var(--light-blue-01);
  flex-shrink: 0;
  margin: 5px 0px;
}

.adherence-block-container {
  background: rgba(128, 204, 228, 0.1);
}

.range-activity-filler {
  height: 1.875rem;
  background: transparent;
  margin: 5px 0px;
}

.range-activity-completed {
  background: var(--light-blue-02);
}

.range-activity-label {
  position: absolute;
  white-space: nowrap;
  user-select: none;
  top: 0%;
  left: 0%;
}

.range-activity-border-left {
  border-left: 5px solid var(--light-blue);
}

.range-activity-border-right {
  border-right: 5px solid var(--light-blue);
}

.adherece-block-border-left {
  border-left: 5px solid #80CCE4
  /* border-left: red; */
}

.adherence-block-border-right {
  border-right: 5px solid #80CCE4;
}