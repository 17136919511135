.activeContainer{
  background: #fff;
}
.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px 0 20px;
  min-height: 46px;

}
.text {
  font-size: 13px;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.title {
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 100px;
  padding-right: 5px;
  white-space: nowrap;
}
.content {
  display: flex;
  width: 100%;
  align-items: center;
}

.rowContainer {
  display: flex;
  padding: 5px 20px 0 20px;
  height: 46px;
  align-items: center;
  width: 100%
}
.datePickerContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
}
.datePickerInput {
  font-size: 12px
}
.select {
  font-size: 12px;
  font-weight: 500;
  height: 35px;
}
