.fileContainer {
  max-width: 70px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  font-size: 11px;
  padding: 5px;
}

.imageContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 35px;
  width: 50px;
}

a:visited {
  color: #1890ff;
}
