.input-unit-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.input-unit-percentage-container {
  background: #f8f9fb;
  border-radius: 3px;
  padding: 0.5rem;
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
}

.input-error {
  border: 1px solid red;
}

.field {
  border: none;
  background: inherit;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.percentage-offset {
  text-align: center;
  font-weight: 500;
  color: var(--gray-low-risk);
}
