.main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 13px;
  padding-bottom: 13px;
}

.main > div > span {
  margin-right: 20px;
}
