.main {
  padding: 19px 19.5px;
  display: flex;
}

.title {
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.text, .disabledText {
  padding-top: 10px;
  font-size: 12px;
  color: red;
}

.disabledText {
  white-space: nowrap;
  padding-top: 10px;
  color: #8faac3;
}

.adherenceGrid {
  margin-left: 25px;
  margin-right: 20px;
  display: flex;
  align-items: center;
}
