.footerContainer {
  min-height: 45px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.container {
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
}
.text {
  font-size: 13px;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.horizontalLine {
  border-color: #efeff0;
  margin: 0;
}

.outgoingMessageContainer {
  padding: 0 20px 20px 20px;
}
.outgoingMessageTitle {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 10px;
}
.outgoingMessage {
  font-size: 12px;
  font-weight: 500;
}
.outgoingMessageInput {
  border: 1px solid #e7eaed;
  width: 100%;
  height: 35px;
  border-radius: 3px;
  padding: 10px;
}
