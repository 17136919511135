.header {
  font-size: 12px;
  font-weight: 500;
  padding-left: 4px;
  padding-top: 0;
}

.week-day {
  font-weight: 400;
  font-size: 12px;
  line-height: 14.4px;
  color: #393e48;
}
.week-days {
  background-color: #f8f9fb;
}
.week-days > *:last-child > *:first-child {
  margin-bottom: 0;
}
.week-day-container {
  background-color: #fff !important;
  margin-bottom: 3px;
}

.week-day-opened {
  background-color: #e7eaed !important;
  margin-bottom: 0 !important ;
}
.verticalLine {
  border: 0;
  width: 1px;
  background: #e7eaed;
  position: absolute;
  left: 10.6%;
  top: 0;
  bottom: 0;
  height: 100%;
  margin: 0;
}
.title {
  flex-grow: 1;
}
