:root {
  --primary_color: #221f4e;
  --secundary_color: #42dab4;
  --hover_color: #373372;
  --blue: #007bff;
  --black-text: #393e48;
  --dark-blue: #282556;
  --dark-blue-border: #3a3587;
  --bright-blue: #1890ff;
  --light-blue: #8ba7f1;
  --light-blue-05: rgba(142, 168, 241, 0.502);
  --light-blue-02: rgba(142, 168, 241, 0.2);
  --light-blue-01: rgba(139, 167, 241, 0.1);
  --blue-700: #5485aa;
  --blue-notification-500: #83acc3;
  --blue-notification-100: #f4fbff;
  --blue-notification-50: #e4f2da;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --red-high-risk: #f5485c;
  --red-error-100: #faf1f1;
  --red-error-50: #f3e0e2;
  --yellow-medium-risk: #f8ca54;
  --yellow-300: #fffbf1;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --green-confirmation-50: #e4f2da;
  --green-confirmation-100: #f7fcf2;
  --green-confirmation-500: #98a185;
  --green-completed: #86b463;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --light-gray: #f9f9fb;
  --gray-border: #efeff0;
  --gray-border-2: #e8ebed;
  --gray-200: #f7f7f9;
  --gray-250: #f5f7fa;
  --gray-300: #e5e5e5;
  --gray-low-risk: #8faac3;
  --gray-tags: #e7eaed;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --high-risk: #f5485c;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
    Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New',
    monospace;
}
