.message {
  padding-top: 15px;
  display: flex;
}
.message-text {
  flex-direction: column;
  background: var(--light-gray);
  padding: 5px 10px;
  border-radius: 5px;
}
.message-time {
  font-size: 11px;
  color: var(--gray-low-risk);
  display: flex;
  justify-content: end;
}
