.emergencyOptionContainer {
    position: absolute;
    top: 90px;
    right: 5px;
    background-color: #FFF6F6;
    color: #F5485C;
    z-index: 10;
    border: 1px solid #F0DBDE;
    filter: drop-shadow(0px 5px 20px rgba(183, 197, 209, 0.4));
}

.emergencyOptionContainer > * {
    padding: 10px;
    font-weight: 500;
    transition: background-color 0.2s;
    cursor: pointer;
    user-select: none;
} 

.emergencyOptionContainer>*:hover {
    background-color: #F5485C;
    color: #FFF6F6;
}