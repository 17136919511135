.bodyContainer {
    font-size: 13px;
}

.rowContainer {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.rowTitle {
    font-weight: 500;
}

.markSelect {
    display: flex;
    align-items: center;
    width: 220px
}

.markInput {
    height: 30px
}

.noteTitle {
    font-weight: 500;
    margin-bottom: 10px;
}

.noteField {
    padding: 5px
}

.checkLabel {
    font-weight: 500;
    margin: 10px 0px;
}
