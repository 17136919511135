.popupContainer {
  background-color: white;
  position: absolute;
  font-size: 13px;
  filter: drop-shadow(0px 5px 20px rgba(183, 197, 209, 0.6));
  border-radius: 5px;
  max-width: 400px;
  z-index: 100;
}

.actionContainer {
  background-color: #f8f9fb;
  padding: 10px;
  border-bottom: 1px solid #efeff0;
}

.commentContainer {
  background-color: #f8f9fb;
}

.sendMessageContainer {
  padding-bottom: 10px;
}
