.control-tab-container {
  background-color: var(--gray-tags);
  border-radius: 5px;
  padding: 3px;
  display: flex;
  flex-basis: 0;
}
.button-control {
  text-transform: uppercase;
  color: #8faac3 !important;
  font-size: 11px;
  font-weight: 500;
  display: flex;
  flex: 1;
  justify-content: center;
  min-height: 23px;
  align-items: center;
  line-height: 13.2px;
}
.selected {
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.15);
  font-weight: 700;
  color: var(--black-text) !important;
}

a.button-control:hover {
  text-decoration: none;
}
