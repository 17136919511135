.container {
  height: 94px;
  display: flex;
  overflow: auto;
  white-space: nowrap;
  position: relative;
  max-width: inherit;
  overflow-x: overlay;
}
.container > * {
  padding-right: 20px !important;
  border-right: 1px solid #efeff0;
}
.container > *:last-child {
  padding-right: 0 !important;
  border-right: 0;
}
.wrapper {
  height: 94px;
  display: flex;
  background-color: white;
}
.arrow {
  transform: rotate(180deg);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 100%;
  cursor: pointer;
}
