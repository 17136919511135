.caseContainer > * {
  margin-bottom: 20px;
}
.caseContainer > *:last-child {
  margin-bottom: 0;
}
.caseContainer {
  height: 100%;
  overflow-y: overlay;
}
.container {
  max-height: 87vh;
}
