.downloadEncounter {
  font-size: 12px;
  font-weight: 500;
  color: #1890ff;
  line-height: 14px;
  cursor: pointer;
}
.encounterLog {
  width: 70%;
  height: 100%;
  font-size: 12px;
}
.summaryFiles {
  width: 25%;
  padding: 20px 0;
}

.innerContainer {
  padding: 20px 0;
}

.innerContainerTop {
  padding-bottom: 10px;
}

.innerContainerBottom {
  padding-top: 10px;
}
