.itemContainer {
    display: flex;
    justify-content: space-between;
    border: 2px solid white;
    padding: 10px;
    cursor: pointer;
    background-color: #F8F9FB;
    align-items: center;
    color:#393E48;
    font-size: 13;
    height: 59px;
}