.container > * {
  border-bottom: 1px solid #efeff0;
}
.container > *:last-child {
  border-bottom: none;
}
.question {
  padding: 20px;
  font-size: 13px;
  font-weight: 400;
}

.answerContainer {
  padding: 10px 0 0 20px;
  font-size: 12px;
  line-height: 24px;
}
