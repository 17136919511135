.container {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding: 17px 20px;
}

.horizontalLine {
  border-color: #efeff0;
  flex-grow: 1;
  margin: 0;
}
.inputTitle {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 10px;
}
.inpoutContainer {
  font-size: 12px;
  font-weight: 500;
}
.backgroundBlue {
  color: blue;
}
.input {
  border: 1px solid #e7eaed;
  width: 100%;
  height: 25px;
  border-radius: 3px;
  background: #f8f9fb;
  padding: 10px;
}
.logo {
  width: 100%;
  object-fit: contain;
}
.imgUploaderContainer {
  background: #f8f9fb;
  height: 20%;
  position: relative;
  min-height: 200px;
  display: flex;
  padding: 1rem;
}

.imgUploader {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.practiceIcon {
  position: relative;
  display: revert;
  width: 20%;
  height: 40%;
  left: 70%;
  top: 10px;
  opacity: 0;
  cursor: pointer;
  z-index: 1;
}
.practiceIconContainer{
  width: 70%;
}
.changeSpan {
  position: relative;
  left: 50%;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  color:#1890FF
}
.practiceIconImg{
  position: relative;
  left: 10%;
  max-width: 25px;
  max-height: 25px;
}
.containerRadio {
  margin: 5px;
}
.span {
  font-size: 12px;
  font-weight: 500;
}

/* Hide the browser's default radio button */
.containerRadio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmarkGreen {
  display: flex;
  height: 15px;
  width: 15px;
  background-color: #eee;
  border-radius: 50%;
  background: conic-gradient(
    from 180deg at 50% 50%,
    #201d4c 0deg,
    #201d4c 181.01deg,
    #7fd1cb 181.04deg,
    #7fd1cb 360deg
  );
  border: 2px solid #393e48;
  box-sizing: border-box;
}
.checkmarkOrange {
  display: flex;
  height: 15px;
  width: 15px;
  background-color: #eee;
  border-radius: 50%;
  background: conic-gradient(
    from 180deg at 50% 50%,
    #f87c23 0deg,
    #f87c23 179.96deg,
    #79be4f 180deg,
    #79be4f 360deg
  );
  border: 2px solid #393e48;
  box-sizing: border-box;
}
.checkmarkYellow {
  display: flex;
  height: 15px;
  width: 15px;
  background-color: #eee;
  border-radius: 50%;
  background: conic-gradient(
    from 180deg at 50% 50%,
    #faca4e 0deg,
    #faca4e 177.65deg,
    #58a5ec 180deg,
    #58a5ec 360deg
  );
  border: 2px solid #393e48;
  box-sizing: border-box;
}
/* On mouse-over, add a grey background color */
.containerRadio:hover input .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.containerRadio input:checked .checkmark {
  border: 5px solid #393e48;
}

/* Show the indicator (dot/circle) when checked */
.containerRadio input:checked .checkmark:after {
  display: block;
}
