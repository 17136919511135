.title {
  font-size: 13px;
  gap: 100px;
}

.header {
  display: flex;
  height: 50px;
  width: 100%;
  justify-content: space-between;
  font-weight: 500;
}

.header > div {
  display: flex;
  align-items: center;
  gap: 10px
}

.header > div:first-child {
  gap: 40px;
}

.searchBox {
  height: 25px;
  width: 220px;
  font-size: 12px;
  position: relative;
}

.filterBtn {
  height: 25px;
}

.timeZoneLabel {
  font-size: 13px;
}
