.cancelButton {
  height: 30px !important;
  width: 80px !important;
  padding: 0 !important;
}
.confirmButton {
  height: 30px !important;
  width: 102px !important;
  padding: 0 !important;
}
.modalBody {
  font-size: 12px;
  font-weight: 400;
  padding: 20px;
  margin: 0;
}
.buttonsContainer {
  display: flex;
  justify-content: space-between;
  padding: 0 20px 20px 20px;
}
