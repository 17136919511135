.iconContainer {
  display: flex;
  justify-content: center;
}

.iconContainer > *:first-child {
  margin-right: 23px;
}

.rule-name {
  color: #1890ff;
  font-weight: 500;
  line-height: 14.4px;
}

.rule-name:hover {
  text-decoration: underline;
}
