.tag-container {
  display: flex;
  flex-wrap: wrap;
  padding: 17px;
}

.tag-container > div {
  margin: 3px;
}

.tagItem {
  padding-left: 4px;
  padding-top: 0px;
}
