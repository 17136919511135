button.actionBtn {
  border-radius: 3px;
  text-transform: uppercase;
  border: 1px solid #1890ff;
  color: #1890ff;
  font-size: 11px;
  background-color: transparent;
  padding: 0 10px;
  height: 25px;
}

button.actionBtn:hover:enabled {
  background-color: #1890ff;
  color: white;
  border: 1px solid white;
}
