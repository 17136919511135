.main {
  background-color: white;
  margin-bottom: 0 !important;
}

.patientName {
  margin-left: 10px;
  font-size: 13px;
  font-weight: 500;
  line-height: 15.6px;
  color: #1890ff;
}

.patientName:hover {
  text-decoration: underline;
  cursor: pointer;
}

.row {
  border-top: 3px solid #f7f7f9;
  background-color: #fff;
}
