.rowContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 20px;
  margin: 1rem 0;
}
.boxShadow {
  box-shadow: 0px -1px 0px #e7eaed, 0px 1px 0px #e7eaed;
}
.boxShadowTop {
  box-shadow: 0px -1px 0px #e7eaed;
}
