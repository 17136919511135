.main {
  height: auto;
}

.body {
  padding: 10px 6.5px 20px 16.5px;
  display: flex;
  flex-wrap: wrap;
}

.controlButton {
  height: 30px !important;
  margin-right: 10px;
  margin-top: 10px;
}
