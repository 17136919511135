.inputContainer {
    display: flex;
    white-space: nowrap;
    min-width: 105px;
    padding-left: 20px;
    margin-left: 5px;
    justify-content: space-between;
    align-items: center;
}

.input {
    border: none;
    border-radius: 5px;
    text-align: center;
    height: 35px;
    width: 40px
}
