.container {
  margin: 10px;
}
.resourcesActions {
  display: flex;
  font-size: 11px;
  height: 25px;
  position: absolute;
  top: 60px;
  right: 20px;
}
.resourcesActions button {
  padding: 0 10px;
  height: 25px;
}
