.buttonContainer {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-right: 50px;
}
.buttonContainer > button:first-child {
  margin-right: 10px;
}
.buttonContainer > button {
  border: none;
  border-radius: 5px;
  padding: 5px 15px;
  background-color: #e7eaed;
  cursor: pointer;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
}
.buttonContainer > button.active {
  background-color: #282556;
  color: #fff;
  font-weight: 700;
}
.item {
  color: #1890ff;
  font-weight: 600;
  font-size: 12px;
  padding: 15px 20px;
  cursor: pointer;
  border-radius: 3px;
}
.item.active {
  background-color: #e7eaed;
}
